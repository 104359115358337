<template>
  <div>Redirection...</div>
</template>

<script>
export default {
  name: "LogoutPage",
  mounted() {
    window.location.href = "https://app.kalm.ai/auth/logout";
  },
}
</script>

<style scoped>

</style>