import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import { StarterKit } from "@tiptap/starter-kit";

import LotOuvrageComponent from './LotOuvrageComponent.vue'
import LotTextComponent from "./LotTextComponent.vue";
import LotHeadingComponent from "./LotHeadingComponent.vue";
import LotContentComponent from "./LotContentComponent.vue";
import {Plugin} from "@tiptap/pm/state";
import {Paragraph} from "@tiptap/extension-paragraph";
import LotParagraphComponent from "@/views/project/description/LotParagraphComponent";
import {v4} from "uuid";

export const lotHeading = Node.create({
    name: "lotHeading",

    disableDropCursor: true,

    addProseMirrorPlugins() {
        return [
            new Plugin({
                name: 'disableDropCursor',
                disableDropCursor () {
                    return true;
                }
            }),
        ];
    },

    addAttributes() {
        return {
            fullname: {
                type: String,
                default: "Lot n°Y – Z"
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'lot-heading',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-heading', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotHeadingComponent)
    },
});

export const lotOuvrage = Node.create({
    name: 'lotOuvrage',
    priority: 10000,

    group: 'lotContentGroup',

    extensions: [
        StarterKit.configure({
            paragraph: false,
        }),
        Paragraph.extend({
            priority: 9999
        })
    ],

    content: 'heading (paragraph|list)*',

    draggable: true,
    disableDropCursor: true,

    addAttributes() {
        return {
            location: {
                type: String,
                default: "",
            },
            locations: {
                type: Array,
                default: [],
            },
            qt: {
                type: String,
                default: "",
            },
            id: {
                type: String,
                default: null,
            }
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                appendTransaction: (transactions, oldState, newState) => {
                    const newTr = newState.tr
                    let modified = false

                    newState.doc.descendants((node, pos) => {
                        if (!!node.type && (node.type.name === 'lotOuvrage')) {
                            const { id, ...rest} = node.attrs
                            if (id === undefined || id === null || id === '') {
                                // Adds a unique id to a node
                                newTr.setNodeMarkup(pos, undefined, { id: v4(), ...rest })
                                modified = true
                            }
                        }
                    })

                    if (modified) {
                        return newTr
                    }
                }
            }),
        ]
    },


    parseHTML() {
        return [
            {
                tag: 'ouvrage',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['ouvrage', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotOuvrageComponent)
    },
})

export const lotText = Node.create({
    name: 'lotText',
    priority: 1200,

    group: 'lotContentGroup',

    extensions: [
        StarterKit.configure({
            paragraph: false,
        }),
        Paragraph.extend({
            priority: 999,
        }),
    ],

    content: '(paragraph|list)*',

    draggable: true,
    disableDropCursor: true,


    parseHTML() {
        return [
            {
                tag: 'lot-text',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-text', mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotTextComponent)
    },
})

export const lotParagraph = Node.create({

    name: "lotParagraph",
    priority: 1000,

    extensions: [
        StarterKit
    ],

    content: "inline*",
    group: "block",

    draggable: true,
    disableDropCursor: true,

    parseHTML() {
        return [
            {
                tag: 'p',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['p', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotParagraphComponent)
    },
})

export const lotContent = Node.create({
    name: "lotContent",
    priority: 10000,

    extensions: [
        lotParagraph,
        lotOuvrage,
        lotText,
    ],

    content: "(lotParagraph|list|lotOuvrage|lotText)+",

    parseHTML() {
        return [
            {
                tag: 'lot-content',
            },
        ]
    },

    addAttributes() {
        return {
            expanded: {
                type: Boolean,
            },
        }
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-content', mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotContentComponent)
    },
});