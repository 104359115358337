<template>
  <node-view-wrapper class="vue-component print-lot" :class="this.$props.node.attrs?.indent > 0 ? 'ms-10' : 'ms-5'">
    <node-view-content></node-view-content>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'

export default {
  name: "LotComponent",
  components: {
    NodeViewWrapper, NodeViewContent
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data() {

  },
  computed: {},
  methods: {
    insertOuvrage() {
      const { editor, getPos, node } = this

      const end = getPos() + node.nodeSize - 2

      editor.commands.insertContentAt(end, '<ouvrage><h4>Nouvel ouvrage</h4><p>Description</p></ouvrage>')
    },
  }
}
</script>

<style scoped>
  @media print {
    .print-lot {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>