<template>
  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <MainMenu></MainMenu>
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <MainHeader></MainHeader>
        <!--end::Header-->
        <!--begin::Main-->
        <div class="d-flex flex-column flex-column-fluid">
          <!--begin::Content-->
          <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
            <!--begin::Container https://docs.google.com/spreadsheets/d/1SiJ0ImxFklAQISSnBuJd5NOiVc5C_Wh-vGRSYIIr96s/edit#gid=1547386264-->
            <div class="container">
              <router-view></router-view>
            </div>
            <!--end::Container-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Main-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Root-->
  <!--begin::Scrolltop-->
  <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
    <!--begin::Svg Icon | path: icons/duotone/Navigation/Up-2.svg-->
    <span class="svg-icon">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<polygon points="0 0 24 0 24 24 0 24" />
						<rect fill="#000000" opacity="0.5" x="11" y="10" width="2" height="10" rx="1" />
						<path d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z" fill="#000000" fill-rule="nonzero" />
					</g>
				</svg>
			</span>
    <!--end::Svg Icon-->
  </div>
</template>

<script>
import MainMenu from "@/views/_core/MainMenu"
import MainHeader from "@/views/_core/MainHeader";

export default {
  name: "App",
  components: {
    MainHeader,
    MainMenu,
  },
  beforeMount() {
    const startPoint = new Date().getTime();
    const delay = 300;
    fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
        .then(response => response.json())
        .then((data) => {
          if(!data.isAuthenticated) {
            window.location.href = process.env.VUE_APP_URL_BACK+"/auth/login";
          }
        })
    while (new Date().getTime() - startPoint <= delay) {
      /* wait */
    }
  },
}
</script>
<style lang="scss">
  @use '@/sass/style.scss';

  .blur-content{
    filter: blur(5px);
  }
</style>