import gql from 'graphql-tag'

export const MUTATION_CONTACT_CREATE = gql`
    mutation ($newContact: ContactInput!,)
    {
        contactCreate(
            newContact: $newContact,
        )
        {
            contact {
                id,
                name,
                email,
                phone,
                type,
                company
            }
        }
    }
`

export const MUTATION_CONTACT_UPDATE = gql`
    mutation (
        $contactId: String!,
        $newContact: ContactInput!,
    )
    {
        contactUpdate(
            contactId: $contactId,
            newContact: $newContact,
        )
        {
            contact {
                id,
                name,
                email,
                phone,
                type,
                company
            }
        }
    }
`

export const MUTATION_CONTACT_DELETE = gql`
    mutation ($contactId: String!)
    {
        contactDelete(
            contactId: $contactId
        )
        {
            deleted
        }
    }
`

export const MUTATION_PROJECT_CREATE_LOT = gql`
    mutation (
        $projectId: UUID!,
        $newLot: LotInput!
    )
    {
        projectLotCreate(
            projectId: $projectId,
            newLot: $newLot,
        ) {
            project {
                id,
                lots {
                    id,
                    name,
                    fullName,
                    number,
                    assignedCompany {
                        id,
                        name,
                        company,
                    },
                    startDate,
                    endDate,
                    comment,
                }
            }
        }
    }
`

export const MUTATION_PROJECT_DELETE_LOT = gql`
    mutation (
        $projectId: UUID!,
        $lotId: UUID!,
    )
    {
        projectLotDelete(
            projectId: $projectId,
            lotId: $lotId,
        ) {
            project {
                id,
                name,
                lots {
                    id,
                    name,
                    fullName,
                    number,
                    assignedCompany {
                        id,
                        name,
                        company,
                    },
                    startDate,
                    endDate,
                    comment,
                },
                featureMultiLocation,
            }
        }
    }
`

export const MUTATION_PROJECT_UPDATE_LOT = gql`
    mutation (
        $projectId: UUID!,
        $lotId: UUID!,
        $newLot: LotInput!,
    )
    {
        projectLotUpdate(
            projectId: $projectId,
            lotId: $lotId,
            newLot: $newLot,
        ) {
            project {
                lots {
                    id,
                    name,
                    fullName,
                    number,
                    assignedCompany {
                        id,
                        name,
                        company,
                    },
                    startDate,
                    endDate,
                    comment,
                }
            }
        }
    }
`

export const MUTATION_CREATE_PROJECT = gql`
    mutation (
        $newProjectName: String!,
        $newInfoOpAddress: String,
        $newInfoOpName: String,
    )
    {
        projectCreate(
            newInfoOpAddress: $newInfoOpAddress,
            newInfoOpName: $newInfoOpName,
            newProjectName: $newProjectName,
        ) {
            project {
                id,
                name,
                address: infoOpAddress,
                infoOpName,
                importantInfo: infoImportantInfo,
                archived,
                document,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    }
                },
                featureMultiLocation,
                lots {id, name, fullName},
                locations {id, name},
            }
        }
    }
`

export const MUTATION_ARCHIVE_PROJECT = gql`
    mutation (
        $projectId: UUID!,
    )
    {
        projectArchive(
            projectId: $projectId,
        ) {
            project {
                id,
                name,
                address: infoOpAddress,
                infoOpName,
                importantInfo: infoImportantInfo,
                archived,
                document,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    }
                },
                featureMultiLocation,
                lots {id, number, name, fullName, startDate, endDate, comment},
                locations {id, name},
            }
        }
    }
`

export const MUTATION_UNARCHIVE_PROJECT = gql`
    mutation (
        $projectId: UUID!,
    )
    {
        projectUnarchive(
            projectId: $projectId,
        ) {
            project {
                id,
                name,
                address: infoOpAddress,
                infoOpName,
                importantInfo: infoImportantInfo,
                archived,
                document,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    }
                },
                featureMultiLocation,
                lots {id, number, name, fullName, startDate, endDate, comment},
                locations {id, name},
            }
        }
    }
`

export const MUTATION_PROJECT_UPDATE = gql`
    mutation (
        $projectId: UUID!,
        $newName: String,
        $newArchived: Boolean,
        $newImportantInfo: String,
        $newInfoOpAddress: String,
        $newInfoOpName: String,
        $newInfoStartDate: Date,
        $newInfoSurface: String,
    )
    {
        projectUpdate(
            projectId: $projectId,
            newName: $newName,
            newArchived: $newArchived,
            newInfoImportantInfo: $newImportantInfo,
            newInfoOpAddress: $newInfoOpAddress,
            newInfoOpName: $newInfoOpName,
            newInfoStartDate: $newInfoStartDate,
            newInfoSurface: $newInfoSurface,
        ) {
            project {
                id,
                name,
                archived,
                address: infoOpAddress,
                infoOpName,
                importantInfo: infoImportantInfo,
                infoStartDate,
                infoSurface
            }
        }
    }
`

export const MUTATION_PROJECT_CREATE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $newLocation: LocationInput!
    )
    {
        projectLocationCreate(
            projectId: $projectId,
            newLocation: $newLocation,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`

export const MUTATION_PROJECT_DELETE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $locationId: UUID!,
    )
    {
        projectLocationDelete(
            projectId: $projectId,
            locationId: $locationId,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`

export const MUTATION_PROJECT_UPDATE_LOCATION = gql`
    mutation (
        $projectId: UUID!,
        $locationId: UUID!,
        $newLocation: LocationInput!,
    )
    {
        projectLocationUpdate(
            projectId: $projectId,
            locationId: $locationId,
            newLocation: $newLocation,
        ) {
            project {
                locations {
                    id,
                    name
                }
            }
        }
    }
`

export const MUTATION_TENDER_CREATE_PROPOSAL = gql`
    mutation (
        $projectId: UUID!,
        $contactId: UUID!
        $modeLots: String!,
        $lots: [UUID]
    )
    {
        projectTenderProposalCreate(
            projectId: $projectId,
            companyId: $contactId,
            modeLots: $modeLots,
            lots: $lots,
        ) {
            proposal {
                id,
                project {
                    id
                },
                contact {
                    id,
                    name,
                    email,
                    phone,
                    type,
                    company
                },
                chiffrages {
                    id,
                    ouvrageId,
                    order,
                    title,
                    description,
                    qt,
                    value,
                    vatRate,
                    vatValue,
                    lot {
                        id,
                        number,
                        fullName,
                    }
                    location {
                        id,
                        name
                    },
                    locations {
                        id,
                        name
                    }
                },
                lots {id, number, fullName},
                modeLots,
                createdDate,
                notes,
                steps,
                vatDefaultValue,
            }
        }
    }
`

export const MUTATION_TENDER_DELETE_PROPOSAL = gql`
    mutation (
        $proposalId: UUID!,
    )
    {
        projectTenderProposalDelete(
            proposalId: $proposalId,
        ) {
            deleted
        }
    }
`

export const MUTATION_TENDER_MANAGE_PROPOSAL = gql`
    mutation (
        $proposalId: UUID!,
        $newProposal: ProposalInput!,
        $modeLots: String!,
        $lots: [UUID],
    )
    {
        projectTenderProposalManage(
            proposalId: $proposalId,
            newProposal: $newProposal,
            modeLots: $modeLots,
            lots: $lots,
        ) {
            proposal {
                id,
                project {
                    id
                },
                contact {
                    id,
                    name,
                    email,
                    phone,
                    type,
                    company
                },
                chiffrages {
                    id,
                    ouvrageId,
                    order,
                    title,
                    description,
                    qt,
                    value,
                    vatRate,
                    vatValue,
                    lot {
                        id,
                        number,
                        fullName,
                    }
                    location {
                        id,
                        name
                    },
                    locations {
                        id,
                        name
                    }
                },
                lots { id, number, fullName},
                modeLots,
                createdDate,
                notes,
                steps,
                vatDefaultValue,
            }
        }
    }
`

export const MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE = gql`
    mutation (
        $proposalId: UUID!,
        $newChiffrage: JSONString!,
    )
    {
        projectTenderProposalChiffrageUpdate(
            proposalId: $proposalId,
            newChiffrage: $newChiffrage,
        ) {
            proposal {
                id,
                project {
                    id
                },
                contact {
                    id,
                    name,
                    email,
                    phone,
                    type,
                    company
                },
                chiffrages {
                    id,
                    ouvrageId,
                    order,
                    title,
                    description,
                    qt,
                    value,
                    vatRate,
                    vatValue,
                    lot {
                        id,
                        number,
                        fullName,
                    }
                    location {
                        id,
                        name
                    },
                    locations {
                        id,
                        name
                    }
                },
                lots { id, number, fullName },
                modeLots,
                createdDate,
                notes,
                steps,
                vatDefaultValue,
            }
        }
    }
`

export const MUTATION_TENDER_CREATE_SCENARIO = gql`
    mutation (
        $projectId: UUID!,
        $name: String!,
        $graph: JSONString!,
    )
    {
        projectTenderScenarioCreate(
            projectId: $projectId,
            name: $name,
            graph: $graph,
        ) {
            scenario {
                id,
                name,
                graph,
                project {
                    id
                }
            }
        }
    }
`

export const MUTATION_TENDER_UPDATE_SCENARIO = gql`
    mutation (
        $scenarioId: UUID!,
        $name: String!,
        $graph: JSONString!,
    )
    {
        projectTenderScenarioUpdate(
            scenarioId: $scenarioId,
            name: $name,
            graph: $graph,
        ) {
            scenario {
                id,
                name,
                graph,
                project {
                    id
                }
            }
        }
    }
`

export const MUTATION_TENDER_DELETE_SCENARIO = gql`
    mutation (
        $scenarioId: UUID!,
    )
    {
        projectTenderScenarioDelete(
            scenarioId: $scenarioId,
        ) {
            deleted
        }
    }
`

export const UPLOAD_DOCUMENT_MUTATION = gql`
    mutation($projectId: String!,
        $newDoc: GenericScalar!,)
    {
        updateProjectDocument(
            projectId: $projectId,
            newDoc: $newDoc,
    )
        {
            project {
                id,
                name,
                document
            }
        }
    }
`


export const MUTATION_PROJECT_UPLOAD_TASKS = gql`
    mutation($projectId: UUID!)
    {
        projectUploadTasks(
            projectId: $projectId
        )
        {
            project {
                id,
                name
            }
        }
    }
`


export const MUTATION_ASSIGN_CONTACT = gql`
    mutation(
        $projectId: UUID!,
        $contactId: UUID!,
        $role: String!,
    )
    {
        projectAssignContact(
            projectId: $projectId,
            contactId: $contactId,
            role: $role,
        )
        {
            project {
                id,
                name,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    }
                }
            }
        }
    }
`


export const MUTATION_UNASSIGN_CONTACT = gql`
    mutation(
        $projectId: UUID!,
        $contactId: UUID!,
    )
    {
        projectUnassignContact(
            projectId: $projectId,
            contactId: $contactId,
        )
        {
            project {
                id,
                name,
                contacts {
                    role,
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    }
                }
            }
        }
    }
`


export const ALL_CONTACTS_QUERY = gql`
    query AllContactsQuery {
        contacts: allContacts {
            id,
            email,
            name,
            phone,
            company,
            type,
        }
    }
`

export const ALL_PROJECTS_QUERY = gql`
    query {
        projects: allProjects {
            id,
            name,
            address: infoOpAddress,
            infoOpName,
            importantInfo: infoImportantInfo,
            archived,
            document,
            contacts {
                role,
                contact {
                    id,
                    name,
                    email,
                    phone,
                    type,
                    company
                }
            },
            lots {
                id,
                name,
                fullName,
                number,
                assignedCompany {
                    id,
                    name,
                    company,
                },
                startDate,
                endDate,
                comment,
            },
            locations {
                id,
                name
            },
            featureMultiLocation,
        }
    }
`

export const GET_PROJECT_BY_ID = gql`
    query ($id: UUID!) {
        project: projectById(id: $id) {
            id,
            name,
            address: infoOpAddress,
            infoOpName,
            importantInfo: infoImportantInfo,
            archived,
            document,
            contacts {
                role,
                contact {
                    id,
                    name,
                    email,
                    phone,
                    type,
                    company
                }
            },
            featureMultiLocation
        }
    }
`

export const GET_PROPOSALS_FOR_PROJECT = gql`
    query ProposalsByProjectId($projectId: UUID!) {
        proposals: proposalsByProjectId(projectId: $projectId) {
            id,
            project {
                id
            },
            contact {
                id,
                name,
                email,
                phone,
                type,
                company
            },
            chiffrages {
                id,
                ouvrageId,
                order,
                title,
                description,
                qt,
                value,
                vatRate,
                vatValue,
                lot {
                    id,
                    number,
                    fullName,
                }
                location {
                    id,
                    name
                },
                locations {
                    id,
                    name
                }
            },
            lots { id, number, fullName },
            modeLots,
            createdDate,
            notes,
            steps,
            vatDefaultValue,
        }
    }
`

export const GET_SCENARIOS_FOR_PROJECT = gql`
    query ScenariosByProjectId($projectId: UUID!) {
        scenarios: scenariosByProjectId(projectId: $projectId) {
            id,
            project {
                id
            },
            name,
            graph
        }
    }
`

export const GET_CRS_FOR_PROJECT = gql`
    query CrsByProjectId($projectId: UUID!) {
        monitoringReports: crsByProjectId(projectId: $projectId) {
            id,
            url,
            reportName,
            reportNumber,
            reportDate,
        }
    }
`

export const MUTATION_TENDER_UPDATE_OUVRAGES = gql`
    mutation ProjectTenderUpdateOuvrages
    (
        $projectId: UUID!
    )
    {
        projectTenderUpdateOuvrages(projectId: $projectId) {
            tender {
                proposals {
                    id,
                    project {
                        id
                    },
                    contact {
                        id,
                        name,
                        email,
                        phone,
                        type,
                        company
                    },
                    chiffrages {
                        id,
                        ouvrageId,
                        order,
                        title,
                        description,
                        qt,
                        value,
                        vatRate,
                        vatValue,
                        lot {
                            id,
                            number,
                            fullName,
                        }
                        location {
                            id,
                            name
                        },
                        locations {
                            id,
                            name
                        }
                    },
                    lots { id, number, fullName },
                    modeLots,
                    createdDate,
                    notes,
                    steps,
                    vatDefaultValue,
                }
            }
        }
    }
`

export const CURRENT_USER_QUERY = gql`
    query CurrentUserQuery {
        currentUser {
            id,
            email,
            firstName,
            lastName,
            groups,
            org {
                id,
                name
            }
            isSuperuser,
            orgs {
                id,
                name
            }
            appsheetUrl,
        }
    }
`

export const MUTATION_SUPERUSER_CHANGE_ORG = gql`
    mutation (
        $newOrgId: UUID!,
    )
    {
        superuserChangeOrg(
            newOrgId: $newOrgId,
        )
        {
            projects {
                id,
                name
            }
        }
    }
`

