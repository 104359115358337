<template>
  <div :class="blurContent ? 'blur-content' : ''"
       class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar mb-5" id="kt_toolbar">
      <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
          <!--begin::Title-->
          <h3 class="text-dark fw-bolder my-1">Mon compte</h3>
          <!--end::Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
            <li class="breadcrumb-item">Paramètres</li>
            <li class="breadcrumb-item text-dark">Mon compte</li>
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Info-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
          <router-link :to="{name: 'settings.account_settings'}" class="btn btn-active-accent active fw-bolder ms-3">Mon compte</router-link>
<!--
          <router-link :to="{name: 'settings.subscription_settings'}" class="btn btn-active-accent fw-bolder ms-3">Mon abonnement</router-link>
-->
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Account-->
        <div class="card">
          <!--begin::Form-->
          <form class="form d-flex flex-center">
            <div class="card-body mw-800px py-20">
              <!--begin::Form row-->
              <div class="row mb-8">
                <label class="col-lg-3 col-form-label">Prénom</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input type="text" class="form-control form-control-lg form-control-solid" :value="currentUser.firstName" placeholder="Prénom">
                  </div>
                </div>
              </div>
              <div class="row mb-8">
                <label class="col-lg-3 col-form-label">Nom</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input type="text" class="form-control form-control-lg form-control-solid" :value="currentUser.lastName" placeholder="Nom">
                  </div>
                </div>
              </div>
              <div class="row mb-8">
                <label class="col-lg-3 col-form-label">Adresse email</label>
                <div class="col-lg-9">
                  <div v-tooltip:bottom="'Contactez le service client pour modifier votre adresse email de connexion'" class="input-group input-group-lg input-group-solid">
														<span class="input-group-text pe-0">
															<i class="la la-at fs-4"></i>
														</span>
                    <input disabled type="text" class="form-control form-control-lg form-control-solid" :value="currentUser.email" placeholder="Email">
                  </div>
                </div>
              </div>
              <!--end::Form row-->
              <!--begin::Form row-->
              <div class="row mb-8">
                <label class="col-lg-3 col-form-label">Langue</label>
                <div class="col-lg-9">
                  <select class="form-select form-select-lg form-select-solid">
                    <option value="fr" selected="selected" data-select2-id="select2-data-3-66je">Français</option>
                  </select>
                </div>
              </div>
              <!--end::Form row-->
              <!--begin::Form row-->
              <div class="row">
                <label class="col-lg-3 col-form-label"></label>
                <div class="col-lg-9">
                  <button type="reset" class="btn btn-primary fw-bolder px-6 py-3 me-3">Enregistrer</button>
                  <button type="reset" class="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3">Annuler</button>
                </div>
              </div>
              <!--end::Form row-->


              <!--begin::Zone de danger-->
<!--              <div class="separator separator-dashed my-10"></div>
              &lt;!&ndash;begin::Form row&ndash;&gt;
              <div class="row mb-13">
                <label class="col-lg-3 col-form-label">Zone dangereuse</label>
                <div class="col-lg-9">
                  <div class="form-text py-2">Pour éviter X</div>
                  <button type="button" class="btn btn-light-danger fw-bold btn-sm">Désactiver mon compte</button>
                </div>
              </div>-->
              <!--end::Zone de danger-->
            </div>
          </form>
          <!--end::Form-->
        </div>
        <!--end::Profile Account-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { CURRENT_USER_QUERY } from "@/graphql/graphql";
import kalmStore from "@/store";

export default {
  name: "SettingsAccountPage",
  apollo: {
    currentUser: CURRENT_USER_QUERY,
  },
  data() {
    return {
      currentUser: {
        email: '',
        firstName: '',
        lastName: ''
      }
    }
  },
  computed: {
    blurContent(){
      return kalmStore.state.display.profileToggled
    }
  }
}
</script>

<style scoped>

</style>