<template>
  <div></div>
  <!--begin::Row-->
<!--  <div class="row g-10 py-5">
    <div class="col-12">
      <h2 class="fw-bold">Raccourcis</h2>
      <div class="mt-2 row g-2 h-100">
        <div class="col-4">
          <div class="card h-100" style="background: linear-gradient(90deg, rgba(6,56,49,1) 0%, rgba(1,148,154,1) 100%) !important;">
            <div class="card-body">
              <router-link is="div" :to="{'name': ''}" class="card-title fs-2 text-white"><span class="me-1 bi bi-file-text"></span> Compléter le descriptif</router-link>
              <div class="card-text text-white">Moulin des prés</div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="card-title fs-2 text-gray-800"><span class="me-1 bi bi-journals"></span> Démarrer la consultation</div>
              <div class="card-text text-gray-600">Moulin des prés</div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="h-50 pb-2">
            <div class="card h-100">
              <div class="card-body">
                <div class="card-title fs-2 text-gray-800"><span class="me-1 bi bi-person-plus"></span> Ajouter un contact</div>
              </div>
            </div>
          </div>
          <div class="h-50">
            <div class="card h-100">
              <div class="card-body">
                <div class="card-title fs-2 text-gray-800"><span class="me-1 bi bi-folder-plus"></span> Créer un nouveau projet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="row g-10 p-5 mb-5 mt-2">
    <div class="col-12 col-md-5">
      <h2 class="fw-bolder fs-2 ms-5 mb-4">Activité récente</h2>
      <div class="row g-2 h-100">
        <div class="col-12 g-2 h-100">
          <div class="card h-auto">
            <div class="card-body">
              <div class="card-title">À venir :-)</div>
              <div class="card-text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7">
      <h2 class="fw-bolder fs-2 ms-5 mb-4">Nouveautés sur Kalm</h2>
      <div class="row g-2 h-100">
        <div class="col-12 g-2 h-100">
          <div class="card h-100">
            <!--begin::Body-->
            <div class="card-body">
              <div id="kt_stats_widget_8_carousel" class="carousel carousel-custom slide" data-bs-ride="carousel" data-bs-interval="8000">
                <!--begin::Heading-->
                <div class="d-flex flex-stack flex-wrap">
                  <!--begin::Label-->
                  <span class="fs-6 text-muted fw-bold pe-2">Découvrez nos dernières fonctionnalités</span>
                  <!--end::Label-->

                  <!--begin::Carousel Indicators-->
                  <ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
                    <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="0" class="ms-1"></li>
                    <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="1" class="ms-1 active" aria-current="true"></li>
                    <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="2" class="ms-1"></li>
                  </ol>
                  <!--end::Carousel Indicators-->
                </div>
                <!--end::Heading-->

                <!--begin::Carousel-->
                <div class="carousel-inner pt-8">
                  <!--begin::Item-->
                  <div class="carousel-item">
                    <!--begin::Section-->
                    <div class="d-flex flex-column justify-content-between h-100">
                      <!--begin::Title-->
                      <h3 class="fs-3 text-gray-800 fw-bold">🎉 Exportez vos documents en PDF</h3>
                      <!--end::Title-->

                      <!--begin::Text-->
                      <p class="text-gray-600 pt-3 mb-0">
                        Vous pouvez désormais profiter d'exports à télécharger en PDF ou à imprimer,
                        avec votre logo et une mise en page plus esthétique, que vous pourrez envoyer
                        à vos clients et entreprises !
                      </p>
                      <!--end::Text-->
                    </div>
                    <!--end::Section-->
                  </div>
                  <!--end::Item-->

                  <!--begin::Item-->
                  <div class="carousel-item active">
                    <!--begin::Section-->
                    <div class="d-flex flex-column justify-content-between h-100">
                      <!--begin::Title-->
                      <h3 class="fs-3 text-gray-800 fw-bold">✨ Copiez/collez depuis Word, Excel et Google Docs</h3>
                      <!--end::Title-->

                      <!--begin::Text-->
                      <p class="text-gray-600 fw-normal pt-3 mb-0">
                        On a travaillé sur les blocs ouvrage et texte, pour les rendre compatibles avec les formats
                        Word, Excel, Google Docs... et vous permettre d'utiliser Kalm en parallèle de ces outils.
                        Donc vous pourrez facilement importer vos anciens descriptifs, clauses de CCTP, etc. depuis
                        des documents existants ! 😌
                      </p>
                      <!--end::Text-->
                    </div>
                    <!--end::Section-->
                  </div>
                  <!--end::Item-->

                  <!--begin::Item-->
                  <div class="carousel-item">
                    <!--begin::Section-->
                    <div class="d-flex flex-column justify-content-between h-100">
                      <!--begin::Title-->
                      <h3 class="fs-3 text-gray-800 fw-bold">⚡️ Créez, dupliquez et archivez vos projets</h3>
                      <!--end::Title-->

                      <!--begin::Text-->
                      <p class="text-gray-600 pt-3 mb-0">
                        On espère que vous utiliserez cette fonctionnalité rapidement ! 😉
                      </p>
                      <!--end::Text-->
                    </div>
                    <!--end::Section-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Carousel-->
              </div>
            </div>
            <!--end::Body-->

            <!--begin::Footer-->
            <div class="card-footer border-0 d-flex flex-stack pt-0 pb-10">
              <!--begin::Label-->
              <span class="text-muted fs-6 fw-semibold pe-2">Une question ? Une idée ?</span>
              <!--end::Label-->

              <a :href="'https://tally.so/r/nWEr4L?user_email='+currentUser.email" target="_blank" class="btn btn-sm btn-primary fw-bold">Je donne mon avis</a>
            </div>
            <!--end::Footer-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script>
//import ProgressCard from "@/views/_core/components/ProgressCard";

import {CURRENT_USER_QUERY} from "@/graphql/graphql";

export default {
  name: "DashboardPage",
  components: {/*ProgressCard*/},
  apollo: {
    currentUser: CURRENT_USER_QUERY,
  },
  data: () => ({
    currentUser: 'unavailable',
  }),
}
</script>

<style scoped>
h1, h2, h3
.card {
  transition: all 50ms ease-in-out;
}
.card.clickable:hover {
  transform: scale(1.01);
  cursor: pointer;
}
</style>