<template>
  <div id="main" class="mb-5">
    <h1 class="text-center text-dmserif mb-0">{{ pageTitle }}</h1>
    <p class="text-center text-muted fs-3">Projet {{ project.name }} — {{ new Date().toLocaleDateString("fr") }}</p>
    <div class="justify-content-center" v-if="project.address">
      <p class="text-center text-muted text-break" v-if="project.infoOpName"><span class="fw-bolder">Opération : </span>{{ project.infoOpName }}</p>
      <p class="text-center text-muted text-break" v-if="project.address"><span class="fw-bolder">Adresse : </span>{{ project.address }}</p>
    </div>
    <div class="mt-10 row justify-content-center">
      <div class="col-5" v-if="this.moe">
        <h3 class="text-dmserif text-center">MOE</h3>
        <p class="mb-0 text-break fw-bolder">{{ moe.company }}</p>
        <p class="mb-0 text-break">{{ moe.name }}</p>
        <p class="mb-0 text-break">{{ moe.phone }}</p>
        <p class="text-break">{{ moe.email }}</p>
      </div>
      <div class="col-1"></div>
      <div class="col-5" v-if="this.moa">
        <h3 class="text-dmserif text-center">MOA</h3>
        <p class="mb-0 text-break fw-bolder" v-if="moa.type === 'CUSTOMER_PRO'">{{ moa.company }}</p>
        <p class="mb-0 text-break fw-bolder" v-else-if="moa.type === 'CUSTOMER_INDIVIDUAL'">{{ moa.name }}</p>
        <p class="mb-0 text-break">{{ moa.phone }}</p>
        <p class="text-break ">{{ moa.email }}</p>
      </div>
    </div>
    <h2 class="text-center mt-5 mb-0 text-dmserif">{{ sectionTitle }}</h2>
  </div>
</template>

<script>
import kalmStore from "@/store";

export default {
  name: "PrintHeader",
  props: {
    pageTitle: {
      type: String,
      default: "",
      required: true
    },
    sectionTitle: {
      type: String,
      default: "",
      required: true
    },
  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    project() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus);
    },
    moa() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).contacts.find(c => c.role === "MOA")?.contact;
    },
    moe() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).contacts.find(c => c.role === "MOE")?.contact;
    },
  },
}
</script>

<style scoped>
  @media print {
    #main {
      display: block;
    }
  }
  @media screen {
    #main {
      display: none;
      visibility: hidden;
    }
  }

  h1 {
    font-size: 26pt;
  }

  h2 {
    font-size: 20pt;
  }

  h3 {
    font-size: 16pt;
  }
</style>

<style>
  .text-dmserif {
    font-family: "DM Serif Display";
    color: #073831;
  }
</style>