<template xmlns:title="http://www.w3.org/1999/xhtml">
  <node-view-wrapper class="vue-component">
    <div @mouseover="this.hover = true"
         @mouseleave="this.hover = false">
      <div class="row g-5 mt-3 mb-3 ms-8 print-ouvrage"
        style="break-inside: avoid;">
        <div class="col-xl-12 mt-0">
          <div class="card">
            <!--begin::Body-->
            <div class="p-0 card-body row">
              <!--begin::Section-->
              <div class="print-ouvrage col-12 col-md-10 col-xl-11 py-10 ps-5 pe-10 d-flex align-items-center me-60px ">
                <div class="ouvrage-left-actions d-flex align-content-center">
                  <div role="button" tabindex="0"
                       @click="plusAction()"
                       contenteditable="false"
                       title="Ajouter une ligne"
                       :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                       class="ouvrage-actions-left ouvrage-action-plus">
                    <svg viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path
                          d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
                    </svg>
                  </div>
                  <div
                      role="button" tabindex="0"
                      contenteditable="false"
                      draggable="true"
                      data-drag-handle
                      title="Déplacer l'ouvrage"
                      class="ouvrage-actions-left drag-handle"
                      :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                  </div>
                </div>

                <!--begin::Content-->
                <div class="w-100">
                  <node-view-content id="ouvrage-node-content" ref="content" class="content p-0" />
                  <div class="row">
                    <div class="col-6 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Emplacement :
                        <LocationMultiselect v-if="projectHasFeatureMultiLocation" :options="locationOptions" v-model="propLocations"></LocationMultiselect>
                        <select v-else class="form-select select text-dark p-0 m-0 fs-6 form-select-white" v-model="propLocation">
                          <option v-for="location in [{name: '', id: ''}, ...this.locationOptions]" :key="location.id" :value="location.id" :selected="this.$props.node.attrs.location === location.id">{{
                              location.name
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6 align-items-center">
                      <div class="mt-3 fs-8 text-muted">
                        Quantité :
                        <input type="text" class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6" v-model="this.propQt">
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Content-->
                <div class="ouvrage-actions position-absolute end-0 row-cols-1 w-60px">

                  <button class="btn btn-outline-secondary"
                          @click="duplicateNode()">
                    <i class="fa fa-light fa-copy"></i>
                  </button>
                  <button class="btn btn-outline-secondary"
                          @click="deleteNode()">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import kalmStore from "@/store";
import { NodeViewContent, NodeViewWrapper } from '@tiptap/vue-3'
import LocationMultiselect from "@/views/project/description/LocationMultiselect";

export default {
  name: "LotOuvrageComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
    LocationMultiselect,
  },
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({
        location: "",
        locations: [],
        qt: "",
        id: "",
      })
    },
    updateAttributes: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      hover: false,
      lots: [],
      selectedLocations: [],
    }
  },
  computed: {
    locationOptions: () => {
      const currentProjectId = kalmStore.getters.currentProjectFocus;
      const project = kalmStore.getters.getProjectById(currentProjectId);
      return project.locations
    },
    projectHasFeatureMultiLocation: () => {
      const currentProjectId = kalmStore.getters.currentProjectFocus;
      const project = kalmStore.getters.getProjectById(currentProjectId);
      return project.featureMultiLocation
    },
    propLocation: {
      get () { return this.node.attrs.location },
      set (value) {
        this.updateAttributes({
          location: value,
        })
      }
    },
    propLocations: {
      get () {
        try {
          return JSON.parse(this.node.attrs.locations) || []
        }
        catch(e) {
          return this.node.attrs.locations || []
        }
      },
      set (value) {
        this.updateAttributes({
          locations: value,
        })
      }
    },
    propQt: {
      get () { return this.node.attrs.qt },
      set (value) {
        this.updateAttributes({
          qt: value,
        })
      }
    },
  },
  methods: {
    deleteNode(){
      const { editor, getPos, node } = this

      const from = getPos()
      const to = from + node.nodeSize

      editor.commands.deleteRange({ from, to })
      editor.commands.focus()
    },
    duplicateNode(){
      const end = this.getPos() + this.node.nodeSize
      console.log("node content --", this.node.content.content)

      const ouvrageCopy = {
        "type": "lotOuvrage",
        "attrs": {
          "location": this.propLocation,
          "locations": this.propLocations,
          "qt": this.propQt,
        },
        "content": JSON.parse(JSON.stringify(this.node.content.content))
      }

      this.editor.chain().insertContentAt(end, ouvrageCopy).focus().run()
    },
    plusAction() {
      const { editor, getPos, node } = this
      const end = getPos() + node.nodeSize

      editor.chain().insertContentAt(end, {type: "lotParagraph", content: []}).focus().run()
    },
    updateLocation(event) {
      const newLoc = event.target.value;
      this.updateAttributes({
        location: newLoc,
      })
    },
    updateQt(event) {
      const newQt = event.target.value;
      this.updateAttributes({
        qt: newQt,
      })
    }
  },
  watch: {
    selectedLocations(newValue) {
      this.updateAttributes({
        locations: newValue,
      })
    },
  }
}
</script>


<style>
.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

.ouvrage-left-actions {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  right: 55px;
}

.drag-handle {
  flex: 0 0 auto;
  order: 2;

  width: 20px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;

  cursor: grab;
}

.ouvrage-actions-left {
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus {
  order: 1;
  transition: background 20ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  fill: rgba(55, 53, 47, 0.35);
  cursor: pointer;
  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus:hover {
  background: rgba(55, 53, 47, 0.08);
}

@media print {
  .drag-handle {
    display: none;
  }
  .field-qt {
    border: 0;
  }
  .field-multilocation {
    border: 0;
    background-image: unset;
  }
  .print-ouvrage {
    break-inside: avoid;
    margin-left: 0 !important;
    padding-left: 10px !important;
    padding-top: 0 !important;
    padding-bottom: 10px !important;
  }
  .ouvrage-left-actions {
    display: none !important;
    visibility: hidden;
  }
}

</style>
