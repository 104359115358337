<template>
  <div class="print-hidden"
       :class="isModalActive ? 'blur-content' : ''">
    <ProjectBreadcrumbComponent
        title="Descriptif"
        current_route="project.description"
        :project_id=$route.params.id
    ></ProjectBreadcrumbComponent>
    <!--begin::Row-->
    <div class="row">
      <div class="col-12">
<!--
        <ProgressCard
            card_type="kalm"
            card_title="Rédaction du descriptif"
            card_subtitle="Dernière modification il y a 2 jours"
            card_text_line_1="7 ouvrages"
            card_text_line_2="3 fournitures"
            :card_progress_percent=80></ProgressCard>
-->
      </div>
    </div>
  </div>
  <div class="container p-0"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="row editor">
      <div v-if="editor && isStoreReady" class="editor-buttons mb-10 justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden">
        <div class="print-hidden editor-toolbar px-3 d-flex align-content-center justify-content-center mw-450px mt-2 mt-lg-0">
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Annuler'" title="Annuler" @click="editor.chain().focus().undo().run()"  :disabled="!editor.can().chain().focus().undo().run()">
            <span class="text-center">
              <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
            </span>
          </button>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Refaire'" title="Refaire" @click="editor.chain().focus().redo().run()"  :disabled="!editor.can().chain().focus().redo().run()">
            <span class="text-center">
              <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
            </span>
          </button>
          <span class="text-muted mx-2 my-auto">|</span>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Imprimer ou télécharger .pdf'" title="Imprimer / Télécharger" @click="printDocument()" >
            <span class="text-center">
              <i class="bi bi-printer fs-2 p-0"></i>
            </span>
          </button>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Télécharger en .XLSX'" title="Télécharger en .XLSX" @click="downloadAsXlsx()" >
            <span class="text-center">
              <i class="bi bi-file-earmark-excel fs-2 p-0"></i>
            </span>
          </button>
          <span class="text-muted mx-2 my-auto">|</span>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Gras'" title="Gras" @click="editor.chain().focus().toggleBold().run()"  :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <span class="text-center">
            <i class="bi bi-type-bold fs-2 p-0"></i>
          </span>
          </button>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Italique'" title="Italique" @click="editor.chain().focus().toggleItalic().run()"  :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
          <span class="text-center">
            <i class="bi bi-type-italic fs-2 p-0"></i>
          </span>
          </button>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Barrer'" title="Barré" @click="editor.chain().focus().toggleStrike().run()"  :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            <span class="text-center">
              <i class="bi bi-type-strikethrough fs-2 p-0"></i>
            </span>
          </button>
          <span class="text-muted mx-2 my-auto">|</span>
          <button class="btn btn-active-light-primary mx-1 p-2" v-tooltip:bottom="'Liste à puces'" title="Liste à puces" @click="editor.chain().focus().toggleBulletList().run()"  :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          <span class="text-center">
            <i class="bi bi-list-ul fs-2 p-0"></i>
          </span>
          </button>
        </div>
        <div class="print-hidden ms-2 mt-2 mt-lg-0 editor-toolbar px-3 d-flex flex-grow-1 align-content-center justify-content-center mw-150px"
            :class="this.leaveGuard ? 'bg-primary cursor-pointer' : ''">
          <div class="mx-1 p-2" :class="this.leaveGuard ? 'text-white fw-bold' : 'text-muted'" @click="saveDocument()" :disabled="!this.leaveGuard">
            Enregistrer
          </div>
        </div>
      </div>
      <div v-if="editor && isStoreReady" class="form__item form__item--title">
        <PrintHeader v-if="this.editor && this.currentFocus" pageTitle="Descriptif Travaux" sectionTitle=""></PrintHeader>
        <div v-if="this.editor && this.currentFocus">
          <FloatingMenu class="floating-menu print-hidden"
            :should-show="this.shouldShowFloatingMenu"
            :editor="this.editor"
            :v-if="this.editor"
            :tippy-options="{}"
            :key="this.floatingMenuKey"
          >
            <button @click="editor.chain().focus().insertContent('<ouvrage><h4></h4></ouvrage>').run()">+ Bloc Ouvrage</button>
            <button @click="editor.chain().focus().insertContent('<lot-text><p>Bloc texte : ajoutez des généralités, un titre...</p></lot-text>').run()">+ Bloc Texte</button>
            <button @click="this.modalImportShow()"><span class="me-2 bi bi-recycle"></span>Importer</button>
          </FloatingMenu>
        </div>
        <editor-content :editor="this.editor" :key="this.floatingMenuKey"/>
      </div>
    </div>
  </div>
  <!-- MODAL - Import from project -->
  <div class="modal"
       tabindex="-1"
       :style="this.modals.import.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                        <p class="text-muted">Choisissez le lot d'un projet ou gabarit dont vous voulez importer le contenu :</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Project Selection-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                        <select v-model="this.modals.import.projectId" class="form-select form-select-solid">
                          <option v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.getProjects]" :disabled="project.disabled" :key="project.id" :value="project.id">{{ project.name }}</option>
                        </select>
                      </div>
                      <!--end::Project Selection-->

                      <!--begin::Lot Selection-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Lot</label>
                        <select v-model="this.modals.import.lotId" class="form-select form-select-solid" >
                          <option v-for="lot in [{id: '', fullName: 'Sélectionnez un lot...', disabled: true}, ...this.modalImportGetLotsForProject()]" :disabled="lot.disabled" :key="lot.id" :value="lot.id">{{ lot.fullName }}</option>
                        </select>
                      </div>
                      <!--end::Lot Selection-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalImportClose()">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2"
                              @click="modalImportValidate()"
                              :disabled="!this.modals.import.projectId || !this.modals.import.lotId"
                      >
                        Importer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kalmStore from "@/store";

import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import PrintHeader from "@/views/project/tender/PrintHeader";
import writeXlsxFile from 'write-excel-file'

import { v4 } from 'uuid';
import { debounce } from '@/utils/debounce'


import StarterKit from '@tiptap/starter-kit'
import { FloatingMenu, Editor, EditorContent } from '@tiptap/vue-3'
import {Placeholder} from "@tiptap/extension-placeholder";

/*
import { Fragment, Slice, Node } from '@tiptap/pm/model'
*/

import { lotContent, lotParagraph, lotOuvrage, lotHeading, lotText } from './LotContentNodes'
import { customDropCursor, docLot, customDocument} from './DocContentNodes'

import { Paragraph } from '@tiptap/extension-paragraph'



export default {
  name: "ProjectDescriptionPage",
  components: {
    FloatingMenu,
    ProjectBreadcrumbComponent,
    EditorContent,
    PrintHeader,
  },
  data() {
    return {
      editor: null,
      leaveGuard: false,
      modals: {
        import: {
          show: false,
          projectId: "",
          lotId: "",
        }
      },
      floatingMenuKey: 0,
    }
  },
  beforeRouteLeave(to, from, next) {
    if(this.leaveGuard) {
      if(window.confirm("Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter ?")){
        next()
      }
      else {
        next(false)
      }
    }
    else { next() }
  },
  methods: {
    async downloadAsXlsx() {
      const doc = this.editor.getJSON();
      const projectId = kalmStore.getters.currentProjectFocus;
      const project = kalmStore.getters.getProjectById(projectId);
      const HEADER_ROW = [
        {
          value: 'lot_id',
          fontWeight: 'bold'
        },
        {
          value: 'lot_name',
          fontWeight: 'bold'
        },
        {
          value: 'location_id',
          fontWeight: 'bold'
        },
        {
          value: 'location_name',
          fontWeight: 'bold'
        },
        {
          value: 'qt',
          fontWeight: 'bold'
        },
        {
          value: 'ouvrage_title',
          fontWeight: 'bold'
        },
        {
          value: 'ouvrage_description',
          fontWeight: 'bold'
        }
      ];
      let ouvrages = []

      function resolveBulletListToString(bulletList, indent=0) {
        let ret = "";

        bulletList.content.forEach(li => {
          if(li?.content) {
            li.content.forEach(el => {
              if(el.type === 'paragraph') {
                let dash = true;
                if(el?.content){
                  el.content.forEach(c => {
                    if(c.type === 'text') {
                      ret += " ".repeat(indent)+(dash ? "- " : "  ")+c.text+" \n";
                      dash = true;
                    }
                    else if(c.type === 'hardBreak') {
                      dash = false;
                    }
                  })
                }
              }
              else if(el.type === 'bulletList') {
                ret += resolveBulletListToString(el, indent+2);
              }
            })
          }
        })

        return ret === "" ? " " : ret;
      }

      // include each docLot's content
      project.lots.forEach(l => {
        const indexLot = doc.content.findIndex(n => n.type === "docLot" && n.attrs.id === l.id)
        doc.content[indexLot].content.filter(n => n.type !== "lotHeading")[0].content.forEach(o => {

          if(o.type === 'lotOuvrage'){

            let title = "";
            let description = "";

            o.content.forEach(l => {
              if(l.type === 'heading' && l?.content) {
                if(l?.content) {
                  l.content.forEach(c => {
                    title += c.text
                  })
                }
                else {
                  title += " "
                }
              }
              else if(l.type === 'paragraph' && l?.content) {
                if(l?.content) {
                  l.content.forEach(c => {
                    if(c.text){
                      description += c.text + "\n"
                    }
                    if(c.type === "hardBreak"){
                      description += "\n"
                    }
                  })
                }
                else {
                  description += " "
                }
              }
              else if(l.type === 'bulletList') {
                if(l?.content) {
                  description += resolveBulletListToString(l);
                }
                else {
                  description += " "
                }
              }
            })

            if(this.projectHasFeatureMultiLocation) {
              let locationsAttribute = []

              try {
                locationsAttribute = JSON.parse(o.attrs.locations) || []
              }
              catch(e) {
                locationsAttribute = o.attrs.locations || []
              }


              ouvrages.push([
                {
                  type: String,
                  value: l.id ? String(l.id) : "-"
                },
                {
                  type: String,
                  value: l.fullName ? String(l.fullName) : "-"
                },
                {
                  type: String,
                  value: locationsAttribute.join(", ") ? locationsAttribute .join(", ") : "-"
                },
                {
                  type: String,
                  value: locationsAttribute.map(loc => project.locations.find(l => l.id === loc)?.name).join(", ") || "-"
                },
                {
                  type: String,
                  value: o.attrs.qt ? String(o.attrs.qt) : "-"
                },
                {
                  type: String,
                  value: title || "-"
                },
                {
                  type: String,
                  value: description || "-"
                },
              ])
            } else {
              ouvrages.push([
                {
                  type: String,
                  value: l.id ? String(l.id) : "-"
                },
                {
                  type: String,
                  value: l.fullName ? String(l.fullName) : "-"
                },
                {
                  type: String,
                  value: o.attrs.location ? String(o.attrs.location) : "-"
                },
                {
                  type: String,
                  value: project.locations.find(l => l.id === o.attrs.location)?.name ? String(project.locations.find(l => l.id === o.attrs.location)?.name) : "-"
                },
                {
                  type: String,
                  value: o.attrs.qt ? String(o.attrs.qt) : "-"
                },
                {
                  type: String,
                  value: title ? String(title) : "-"
                },
                {
                  type: String,
                  value: description ? String(description) : "-"
                },
              ])
            }
          }
          else if (o.type === 'lotText') {
            let description = "";

            o.content.forEach(l => {
              if(l.type === 'paragraph' && l?.content) {
                if(l?.content) {
                  l.content.forEach(c => {
                    if(c.text){
                      description += c.text + "\n"
                    }
                    if(c.type === "hardBreak"){
                      description += "\n"
                    }
                  })
                }
                else {
                  description += " "
                }
              }
              else if(l.type === 'bulletList') {
                if(l?.content) {
                  description += resolveBulletListToString(l);
                }
                else {
                  description += " "
                }
              }
            })

            ouvrages.push([
              {
                type: String,
                value: l.id ? String(l.id) : "-"
              },
              {
                type: String,
                value: l.fullName ? String(l.fullName) : "-"
              },
              {
                type: String,
                value: "-"
              },
              {
                type: String,
                value: "-"
              },
              {
                type: String,
                value: "-"
              },
              {
                type: String,
                value: "-"
              },
              {
                type: String,
                value: description ? String(description) : "-"
              },
            ])
          }
        })

      });

      const data = [HEADER_ROW, ...ouvrages];
      const today_date = (new Date()).toISOString().slice(0, 10);
      await writeXlsxFile(data, {
        fileName: `kalm_descriptif_${projectId}_${today_date}.xlsx`, // todo add project name and date in file name
      })
    },
    fetchFocusDoc() {
      const projectId = kalmStore.getters.currentProjectFocus;
      const project = kalmStore.getters.getProjectById(projectId);

      let newDoc;

      console.log("fetching doc...", projectId)
      console.log(project)

      // TODO project is empty, we need to wait until it's initialized

      if(project?.doc?.content) {
        // EXISTING DOCUMENT - CHERRY PICKING
        // include beginning content
        newDoc = {
          "type": "docType",
          "content": project.doc.content.filter(n => n.type !== "docLot")
        };

        // include each docLot's content
        project.lots.forEach(l => {
          const indexLot = project.doc.content.findIndex(n => n.type === "docLot" && n.attrs.id === l.id)
          let lotContent;

          if(indexLot === -1 || project.doc.content[indexLot].content.filter(n => n.type === "lotContent").length === 0) {
            lotContent = [
              {
                "type": "lotHeading",
                "attrs": {
                  "fullname": l.fullName
                }
              },
              {
                "type": "lotContent",
                "attrs": {
                  "fullname": l.fullName,
                  "expanded": true,
                },
                "content": [
                  {
                    "type": "lotParagraph",
                    "content": [
                      {
                        "type": "text",
                        "text": "Note : ajoutez ici le contenu du lot "+l.number
                      }
                    ]
                  },
                ]
              },
            ]
          }
          else {
            lotContent = [
              {
                "type": "lotHeading",
                "attrs": {
                  "fullname": l.fullName
                }
              },
              {
                "type": "lotContent",
                "attrs": {
                  "expanded": project.doc.content[indexLot].content.find(n => n.type === "lotContent")?.attrs?.expanded
                },
                "content": project.doc.content[indexLot]
                    .content.find(n => n.type === "lotContent")
                    .content.map(function(n) {
                      n.type = n.type === "paragraph" ? "lotParagraph" : n.type;
                      if (!n?.attrs) {
                        n.attrs = { "id": v4() }
                      }
                      else if (!n.attrs?.id) {
                        n.attrs.id = v4();
                      }
                      return n;
                    })
              },
            ]
          }
          newDoc.content.push({
            "type": "docLot",
            "attrs": l = {
              "id": l.id,
              "indent": l.number.includes(".") ? 1 : 0,
            },
            "content": lotContent
          })
        });
      }
      else {
        // NO EXISTING DOCUMENT, INITIALIZE FROM SCRATCH
        newDoc = '<p></p>'
        project.lots.forEach(l => {
          newDoc += `<lot id="${l.id}"><lot-heading fullname="${l.fullName}"></lot-heading><lot-content><p>Contenu du nouveau lot</p></lot-content></lot>`;
        });
      }

      //const ret = (project.doc && project.doc !== "") ? project.doc : defaultDoc;
      return newDoc;
    },
    shouldShowFloatingMenu({state}) {
      let isLotContentExpanded = false;
      state.selection.$anchor.path.forEach(n => {
        if(typeof n !== 'number'
            && 'type' in n && 'name' in n.type
            && 'attrs' in n && 'expanded' in n.attrs
            && n.type.name === "lotContent"
            && n.attrs.expanded){
          isLotContentExpanded = true;
        }
      });

      return state.selection.$anchor.parent.type.name === 'lotParagraph'
          && state.selection.$anchor.nodeBefore === null
          && state.selection.$anchor.nodeAfter === null
          && isLotContentExpanded;
    },
    syncDocument(newJSONDoc) {
      kalmStore.dispatch("syncFocusDoc", {
        apolloClient: this.$apollo,
        newJSONDoc
      })
      this.leaveGuard = true;
    },
    saveDocument() {
      kalmStore.dispatch("pushFocus", {apolloClient: this.$apollo})
      this.leaveGuard = false;
    },
    printDocument() {
      window.print();
    },
    loadEditor() {
      console.log("loading empty editor")
      this.floatingMenuKey++;
      this.editor = new Editor({
        extensions: [
          customDocument,
          docLot,
          lotHeading,
          lotContent,
          lotParagraph,
          lotOuvrage,
          lotText,
          StarterKit.configure({
            document: false,
            dropcursor: false,
            paragraph: false,
          }),
          Placeholder.configure({
            includeChildren: true,
            showOnlyCurrent: false,
            placeholder: ({ node }) => {
              switch(node.type.name) {
                case 'heading':
                  return 'Titre — Fourniture et pose d\'un robinet mitigeur'
                case 'paragraph':
                  if(node) {
                    return 'Description — référence, dimensions...'
                  }
                  else {
                    return 'Bloc texte — ajoutez des généralités, un titre...'
                  }
                case 'lotText':
                  return 'Bloc texte : ajoutez des généralités, un titre...'
                default:
                  return 'Description...'
              }
            },
          }),
          Paragraph.extend({
            priority: 1001,
          }),
          customDropCursor.configure({
            width: 4,
            color: "rgba(1,148,154,0.32)",
          }),
        ],

        editorProps: {
          handleDrop: (view, event, slice, moved) => {
            let ret = true;

            let pos = view.posAtCoords({
              left: event.clientX,
              top: event.clientY
            })

            let node = pos && pos.inside >= 0 && view.state.doc.nodeAt(pos.inside)

            let disabled = node && node.type && (node.type.name === "lotHeading" || node.type.name === "docLot")

            if (moved && pos && !disabled) {
              ret = false;
            }

            return ret;
          },
        },
      })

      this.editor.on('update',
        debounce(({editor}) => this.syncDocument(editor.getJSON()), 1000)
      );
    },
    modalImportClose() {
      this.modals.import.show = false;
      this.modals.import.projectId = ""
      this.modals.import.lotId = ""
    },
    modalImportShow() {
      this.modals.import.projectId = ""
      this.modals.import.lotId = ""
      this.modals.import.show = true;
    },
    modalImportValidate() {
      // fetch ouvrages for project & lot
      const project = kalmStore.getters.getProjectById(this.modals.import.projectId);
      const content = project.doc
                        .content.find(n => n.type === "docLot" && n.attrs.id === this.modals.import.lotId)
                        .content.find(n => n.type === "lotContent")
                        .content.filter(n => (n.type === "lotOuvrage" || n.type === "lotText"))
                        .map(n => {
                          // cleanup location field
                          if(n.type === "lotOuvrage") {
                            n.attrs.location = ""
                          }
                          return n
                        })
      ;

      // import eventual content into current document
      if(content.length > 0) {
        this.editor.commands.insertContent(`<p>[IMPORT] Les ouvrages suivants sont importés depuis le lot ${kalmStore.getters.getProjectById(this.modals.import.projectId).lots.find(l => l.id === this.modals.import.lotId).name} du projet ${kalmStore.getters.getProjectById(this.modals.import.projectId).name}.</p>`);
        this.editor.commands.focus();

        const preImportMessage = {
          "type": "lotParagraph",
          "content": [
            {
              "type": "text",
              "text": `[IMPORT] Les ouvrages suivants sont importés depuis le lot ${kalmStore.getters.getProjectById(this.modals.import.projectId).lots.find(l => l.id === this.modals.import.lotId).name} du projet ${kalmStore.getters.getProjectById(this.modals.import.projectId).name}`
            }
          ]
        }
        const postImportMessage = {
          "type": "lotParagraph",
          "content": [
            {
              "type": "text",
              "text": `[IMPORT] Les ouvrages précédents sont importés depuis le lot ${kalmStore.getters.getProjectById(this.modals.import.projectId).lots.find(l => l.id === this.modals.import.lotId).name} du projet ${kalmStore.getters.getProjectById(this.modals.import.projectId).name}`
            }
          ]
        }

        this.editor.commands.insertContent([preImportMessage, ...content, postImportMessage]);
        this.editor.commands.focus();
      }
      else {
        // todo popup message aucun ouvrage
      }

      this.modalImportClose();
    },
    modalImportGetLotsForProject(){
      return kalmStore.getters.getProjectById(this.modals.import.projectId).lots;
    },
    beforeWindowUnload(e) {
      console.log(this.leaveGuard)
      if(this.editor && this.leaveGuard) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted() {
    console.log("mounted")
    this.loadEditor();
    if(this.isStoreReady){
      console.log("mounted - doc is ready")
      this.editor.commands.setContent(this.fetchFocusDoc())
    }
    else {
      console.log("mounted - doc is not ready")
    }
  },
  watch: {
    'currentFocus': function(newValue, oldValue) {
      if(this.isStoreReady){
        console.log("currentFocus changed", oldValue, newValue)
        console.log(this.editor)
        console.log(this.fetchFocusDoc())
        this.editor.commands.insertContent(this.fetchFocusDoc())
      }
      else {
        console.log("currentFocus changed - store not ready")
        this.editor.destroy()
        this.loadEditor()
      }
    },
    'isStoreReady': function(newValue) {
      if(newValue) {
        console.log("isStoreReady - yes - ", this.$route.params.id, kalmStore.getters.currentProjectFocus)
        console.log("setting content with", this.fetchFocusDoc())
        this.editor.commands.setContent(this.fetchFocusDoc())
      }
      else {
        console.log("isStoreReady - no - ", this.$route.params.id, kalmStore.getters.currentProjectFocus)
      }
    }
  },
  computed: {
    isStoreReady(){
      if (kalmStore.getters.currentProjectFocus === 0
          || kalmStore.getters.currentProjectFocus === "0"
          || kalmStore.getters.currentProjectFocus === null) {
        console.log("store not ready -- project 0")
        return false
      }
      else if(this.$route.params.id !== kalmStore.getters.currentProjectFocus) {
        console.log("store not ready -- project and route not matching")
        return false
      }
      else if(!kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).ready) {
        console.log("store not ready -- descr returning empty project doc")
        return false
      }
      else {
        console.log("store is ready -- let's go")
        return true
      }
    },
    isModalActive(){
      return kalmStore.state.display.profileToggled || this.modals.import.show;
    },
    currentFocus(){
      return kalmStore.getters.currentProjectFocus;
    },
    getProjects(){
      return kalmStore.state.description.projects.filter(p => p.id !== this.currentFocus)
    },
    projectHasFeatureMultiLocation: () => {
      const currentProjectId = kalmStore.getters.currentProjectFocus;
      const project = kalmStore.getters.getProjectById(currentProjectId);
      return project.featureMultiLocation
    },
  },
  beforeUnmount() {
    console.log("unmounted")
    if(this.editor){
      this.editor.destroy();
    }
    document.removeEventListener('keydown', this._keyListener);
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
}
</script>

<style scoped>
.editor-buttons {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 180;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  right: 50px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

.floating-menu {
  display: flex;
  padding: 0.2rem;
  border-radius: 0.5rem;
  z-index: 100 !important;
}
.floating-menu button {
  position: relative;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  z-index: 100 !important;
}
.floating-menu button:hover, .floating-menu button.is-active {
  opacity: 1;
}

.editor-toolbar {
  border-radius: 24px;
  background-color: rgba(228, 238, 238, 0.93);
}

@media print {
  .card {
    box-shadow: none;
  }

  .print-hidden {
    display: none !important;
  }
}
</style>

<style>
.ProseMirror h4.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h4 + p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror #lotText-node-content > p.is-empty::before {
  color: #adb5bd;
  content: "Bloc texte : ajoutez des généralités, un titre...";
  float: left;
  height: 0;
  pointer-events: none;
}

@media print {
  .tooltip {
    display: none !important;
    visibility: hidden !important;
  }
}
</style>