<template>
  <node-view-wrapper class="vue-component ">
    <div @mouseover="this.hover = true"
         @mouseleave="this.hover = false">
      <div class="row g-5 mt-1 mb-1 print-paragraph">
        <div class="col-xl-12 mt-0">
          <div class="">
            <!--begin::Body-->
            <div class="p-0 row">
              <!--begin::Section-->
              <div class="print-paragraph col-12 col-md-10 col-xl-11 ps-5 py-1 pe-10 d-flex align-items-center me-60px ">
                <div class="paragraph-left-actions d-flex align-content-center">
                  <div role="button" tabindex="0"
                       @click="plusAction()"
                       contenteditable="false"
                       title="Ajouter une ligne"
                       :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                       class="ouvrage-actions-left ouvrage-action-plus">
                    <svg viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path
                          d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
                    </svg>
                  </div>
                  <div
                      role="button" tabindex="0"
                      contenteditable="false"
                      draggable="true"
                      data-drag-handle
                      title="Déplacer le texte"
                      class="ouvrage-actions-left drag-handle"
                      :style="'visibility: ' + (this.hover ? 'visible' : 'hidden')"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                         style="width: 15px; height: 100%; display: block;">
                      <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                  </div>
                </div>

                <!--begin::Content-->
                <div class="w-100">
                  <node-view-content id="ouvrage-node-content" ref="content" class="content p-0" />
                </div>
                <!--end::Content-->
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, NodeViewWrapper } from '@tiptap/vue-3'

export default {
  name: "LotParagraphComponent",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  data() {
    return {
      hover: false,
    }
  },
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({
        id: "",
      }),
    },
  },
  computed: {
  },
  methods: {
    deleteNode(){
      const { editor, getPos, node } = this

      const from = getPos()
      const to = from + node.nodeSize

      editor.commands.deleteRange({ from, to })
      editor.commands.focus()
    },
    duplicateNode(){
      const { editor, getPos, node } = this

      const end = getPos() + node.nodeSize

      const innerContent = editor.view.nodeDOM(getPos()).querySelector("#ouvrage-node-content").innerHTML;

      editor.commands.insertContentAt(end, `<ouvrage location="${this.propLocation}" qt="${this.node.attrs.qt}">${innerContent}</ouvrage>`)
      editor.commands.focus()
    },
    plusAction() {
      const { editor, getPos, node } = this

      const end = getPos() + node.nodeSize

      editor.chain().insertContentAt(end, {type: "lotParagraph", content: []}).focus().run()
    },
  }
}
</script>

<style>

.ProseMirror-focused:focus {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror-focused:focus-visible {
  outline: none !important;
}
.ProseMirror:focus-visible {
  outline: none !important;
}

.paragraph-left-actions {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  right: 21px;
}

.drag-handle {
  flex: 0 0 auto;
  order: 2;

  width: 20px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;

  cursor: grab;
}

.ouvrage-actions-left {
  display: flex;
  gap: 10px;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus {
  order: 1;
  transition: background 20ms ease-in 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  fill: rgba(55, 53, 47, 0.35);
  cursor: pointer;
  border-radius: 3px;
  pointer-events: auto;
}

.ouvrage-action-plus:hover {
  background: rgba(55, 53, 47, 0.08);
}

@media print {
  .drag-handle {
    display: none;
  }
  .field-qt {
    border: 0;
  }
  .print-paragraph {
    display: none;
  }
  .ouvrage-left-actions {
    display: none;
  }
}

/*.vue-component {
  background: #FAF594;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}

.label {
  margin-left: 1rem;
  background-color: #0D0D0D;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.content {
  margin: 2.5rem 1rem 1rem;
  padding: 0.5rem;
  border: 2px dashed #0D0D0D20;
  border-radius: 0.5rem;
}*/
</style>