import { createRouter, createWebHistory } from 'vue-router'

import ContactsPage from "@/views/contacts/ContactsPage";
import DashboardPage from "@/views/dashboard/DashboardPage";
import ContactDetail from "@/views/contacts/ContactDetail";
import ProjectsPage from "@/views/project/ProjectsPage";
import ProjectHomePage from "@/views/project/home/ProjectHomePage";
import ProjectDescriptionPage from "@/views/project/description/ProjectDescriptionPage";
import ProjectMonitoringPage from "@/views/project/monitoring/ProjectMonitoringPage";
import SettingsAccountPage from "@/views/settings/SettingsAccountPage";
import SettingsSubscriptionPage from "@/views/settings/SettingsSubscriptionPage";
import ProjectDetail from "@/views/project/ProjectDetail";
import LogoutPage from "@/views/project/LogoutPage";
import WrapperPage from "@/views/_core/WrapperPage";
import ProjectTenderManagePage from "@/views/project/tender/ProjectTenderManagePage";
import ProjectTenderTablePage from "@/views/project/tender/ProjectTenderTablePage";
import ProjectTenderScenariosPage from "@/views/project/tender/ProjectTenderScenariosPage";


function authGuard(to, from, next) {
    fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
        .then(response => response.json())
        .then((data) => {
            if(!data.isAuthenticated) {
                window.location.href = process.env.VUE_APP_URL_BACK+"/auth/login";
            }
            else {
                next();
            }
        })
}

const routes = [
    {
        path: '/',
        name: 'root',
        redirect: '/dashboard',
        beforeEnter: authGuard,
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPage,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage,
        beforeEnter: authGuard,
        meta: {
            title: "Tableau de bord"
        }
    },
    {
        path: '/settings',
        component: WrapperPage,
        beforeEnter: authGuard,
        children: [
            {
                path: '',
                name: 'settings',
                redirect: '/settings/account',
            },
            {
                path: 'account',
                name: 'settings.account_settings',
                component: SettingsAccountPage,
                meta: {
                    requiresAuth: true,
                    title: "Paramètres"
                }
            },
            {
                path: 'subscription',
                name: 'settings.subscription_settings',
                component: SettingsSubscriptionPage,
                meta: {
                    requiresAuth: true,
                    title: "Paramètres"
                }
            }
        ]
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsPage,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: "Contacts"
        }
    },
    {
        path: '/contacts/:id',
        name: 'contact_detail',
        component: ContactDetail,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: "Contact"
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: ProjectsPage,
        beforeEnter: authGuard,
        meta: {
            requiresAuth: true,
            title: "Mes projets"
        }
    },
    {
        path: '/projects/:id',
        component: ProjectDetail,
        beforeEnter: authGuard,
        children: [
            {
                path: '',
                name: 'project_home',
                component: ProjectHomePage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Projet"
                }
            },
            {
                path: 'consultation',
                component: WrapperPage,
                beforeEnter: authGuard,
                children: [
                    {
                        path: '',
                        name: 'project.tender',
                        redirect: {name: 'project.tender.manage'},
                    },
                    {
                        path: 'manage',
                        name: 'project.tender.manage',
                        component: ProjectTenderManagePage,
                        meta: {
                            requiresAuth: true,
                            title: "Consultation - Suivi"
                        }
                    },
                    {
                        path: 'table',
                        name: 'project.tender.table',
                        component: ProjectTenderTablePage,
                        meta: {
                            requiresAuth: true,
                            title: "Consultation - Comparatif"
                        }
                    },
                    {
                        path: 'scenarios',
                        name: 'project.tender.scenarios',
                        component: ProjectTenderScenariosPage,
                        meta: {
                            requiresAuth: true,
                            title: "Consultation - Scénarios"
                        }
                    },
                ]
            },
            {
                path: 'description',
                name: 'project.description',
                component: ProjectDescriptionPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Descriptif"
                }
            },
            {
                path: 'monitoring',
                name: 'project.monitoring',
                component: ProjectMonitoringPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Suivi de chantier"
                }
            },
        ]
    },
]
const kalmRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/* TODO : add auth check

router.beforeEach((to) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth && !auth.isLoggedIn()) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/login',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})*/

export default kalmRouter