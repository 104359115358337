<template>
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="col-xl-12">
      <!--begin::Table Widget 2-->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes contacts</span>
            <span class="text-muted mt-2 fw-bold fs-6">{{ contacts_count }} contacts</span>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-light justify-content-end">
<!--              <li class="nav-item align-middle">
                <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="openContactModal"><i class="bi bi-cloud-download"></i> Importer</button>
              </li>-->
              <li class="nav-item align-middle">
                <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="openContactModal">+ Ajouter</button>
              </li>
              <li>
                <input class="form-control form-control-solid ms-3" v-model="this.contact_search_filter" placeholder="Rechercher">
              </li>
            </ul>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div class="tab-content mt-4" id="myTabTables2">
            <div class="table-responsive">
              <table class="table table-borderless align-middle">
                <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-120px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-70px"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="contact in getContactsFiltered" :key="contact.id">
                  <td class="px-0 py-3">
                    <div class="symbol symbol-55px mt-1 me-5">
																				<span class="symbol-label bg-light-primary align-items-end">
																					<img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
																				</span>
                    </div>
                  </td>
                  <td class="px-0">
                    <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ contact.company || contact.name }}</a>
                    <span class="text-muted fw-bold d-block mt-1">{{ contact.phone }}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-gray-800 fw-bolder d-block fs-6">{{ contact.company }}</span>
                  </td>
                  <td class="text-center">
                    <span class="text-gray-800 d-block fs-6">{{ contact.email }}</span>
                  </td>
                  <td class="text-end pe-0">
                    <btn class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                      @click="modalConfirmDeleteShow(contact.id)">
                      <span class="bi bi-trash-fill"></span>
                    </btn>
                    <btn class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                      @click="modalEditContactShow(contact.id)">
                      <span class="bi bi-pencil-fill"></span>
                    </btn>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <!--end::Row-->
  <!-- MODAL - CREATE CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="contact_modal_active ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="closeContactModal">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      @submit.prevent="apolloAddNewContactFromModal"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouveau Contact</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom(s)</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="newcontact_name" placeholder="Jean & Laura Dupont" value="">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="newcontact_phone" placeholder="+33 6 23 45 67 89" value="">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="newcontact_email" placeholder="jean.dupont@gmail.com" value="">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                        <input type="text" class="form-control form-control-lg form-control-solid" name="newcontact_company" placeholder="Sanzot SARL" value="">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row" v-for="typeOption in contact_modal_type_options" :key="typeOption.id">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{ typeOption.title }}</span>
                              <span class="fs-7 text-muted">{{ typeOption.subtitle }}</span>
                            </span>
                          </span>
                          <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input" type="radio" :checked="typeOption.checked" name="newcontact_type" :value="typeOption.id">
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="submit" class="btn btn-lg btn-primary btn-active-light-primary">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL CONFIRM DELETE CONTACT-->
  <div class="modal"
       tabindex="-1"
       :style="modalConfirmDelete.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalConfirmDeleteCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                      </div>
                      <p>
                        Pour supprimer un contact, assurez-vous qu'il n'est associé à aucun projet en tant qu'intervenant, consultation ou tâche de suivi de chantier.
                      </p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalConfirmDeleteCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalConfirmDeleteValidate" class="btn btn-lg btn-primary ms-2">
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="this.modalEditContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditContactCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                      class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier le contact</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom(s)</label>
                        <input v-model="modalEditContact.current.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                        <input v-model="modalEditContact.current.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                        <input v-model="modalEditContact.current.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="jean.dupont@gmail.com">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                        <input v-model="modalEditContact.current.company" type="text" class="form-control form-control-lg form-control-solid" placeholder="Sanzot SARL">
                        <div class="fv-plugins-message-container invalid-feedback">Caz</div></div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row"
                           v-for="typeOption in contact_modal_type_options"
                           :key="typeOption.id">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{ typeOption.title }}</span>
                              <span class="fs-7 text-muted">{{ typeOption.subtitle }}</span>
                            </span>
                          </span>
                          <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input" type="radio" v-model="modalEditContact.current.type"
                                   :value="typeOption.id">
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalEditContactCancel()">
                        Annuler
                      </button>
                      <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                              @click="modalEditContactValidate()">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  ALL_CONTACTS_QUERY,
  MUTATION_CONTACT_CREATE,
  MUTATION_CONTACT_DELETE,
  MUTATION_CONTACT_UPDATE,
} from '@/graphql/graphql'
import kalmStore from "@/store";

function sortContacts(a,b) {
  return ('' + a.name).localeCompare(('' + b.name));
}

export default {
  name: "ContactsPage",
  apollo: {
    contacts: ALL_CONTACTS_QUERY,
  },
  components: {  },
  methods: {
    getContactById(id) {
      return this.contacts.find(c => c.id === id);
    },
    modalEditContactShow(id) {
      if(id) {
        this.modalEditContact.show = true;
        this.modalEditContact.id = id;
        this.modalEditContact.current.name = this.getContactById(id).name;
        this.modalEditContact.current.email = this.getContactById(id).email;
        this.modalEditContact.current.phone = this.getContactById(id).phone;
        this.modalEditContact.current.company = this.getContactById(id).company;
        this.modalEditContact.current.type = this.getContactById(id).type;
      }
    },
    modalEditContactCancel() {
      this.modalEditContact.show = false;
    },
    modalEditContactValidate() {
      this.modalEditContact.show = false;

      const contactId = this.modalEditContact.id;
      const newContact = {
        name: this.modalEditContact.current.name,
        email: this.modalEditContact.current.email,
        phone: this.modalEditContact.current.phone,
        company: this.modalEditContact.current.company,
        type: this.modalEditContact.current.type,
      }

      if (contactId) {
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_UPDATE,
              variables: {
                contactId,
                newContact
              },
              update: (store, { data: { contactUpdate }  }) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({query: ALL_CONTACTS_QUERY})

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId),
                    contactUpdate.contact
                  ].sort(sortContacts),
                }

                // Write our data back to the cache.
                store.writeQuery({query: ALL_CONTACTS_QUERY, data})
              },
            }
        )
      }
    },
    openContactModal() {
      this.contact_modal_active = true;
    },
    closeContactModal() {
      this.contact_modal_active = false;
    },
    modalConfirmDeleteShow(id) {
      this.modalConfirmDelete.id = id
      this.modalConfirmDelete.show = true;
    },
    modalConfirmDeleteValidate() {
      const contactId = this.modalConfirmDelete.id;

      if(contactId){
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_DELETE,
              variables: {
                contactId
              },
              update: (store) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({ query: ALL_CONTACTS_QUERY })

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId)
                  ].sort(sortContacts),
                }
                // Write our data back to the cache.
                store.writeQuery({ query: ALL_CONTACTS_QUERY, data })
              },
            }
        )
      }

      this.modalConfirmDelete.id = "";
      this.modalConfirmDelete.show = false;
    },
    modalConfirmDeleteCancel() {
      this.modalConfirmDelete.show = false;
    },
    apolloAddNewContactFromModal(submitEvent) {
      // Model - clean-up input
      let newContact = {}
      newContact.phone = submitEvent.target.elements.newcontact_phone.value;
      newContact.email = submitEvent.target.elements.newcontact_email.value;
      newContact.name = submitEvent.target.elements.newcontact_name.value;
      newContact.company = submitEvent.target.elements.newcontact_company.value;
      newContact.type = submitEvent.target.elements.newcontact_type.value;
      newContact.privateContact = false;

      // Model - GraphQL Mutation to add new contact
      this.$apollo.mutate({
        mutation: MUTATION_CONTACT_CREATE,
        variables: {
          newContact,
        },
        update: (store, { data: { contactCreate } }) => {
          // Read the data from our cache for this query.
          let data = store.readQuery({ query: ALL_CONTACTS_QUERY })

          // Add new contact from the mutation to cache
          data = {
            ...data,
            contacts: [
              ...data.contacts,
              contactCreate.contact,
            ].sort(sortContacts)
          }
          // Write our data back to the cache.
          store.writeQuery({ query: ALL_CONTACTS_QUERY, data })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          contactCreate: {
            __typename: 'ContactCreate',
            contact: {
              __typename: 'ContactGType',
              id: "-1",
              company: newContact.company,
              phone: newContact.phone,
              email: newContact.email,
              name: newContact.name,
              type: newContact.type,
            },
          }
        },
      })

      // UI - close modal and empty out form fields
      this.closeContactModal();
      this.$refs.contactModalForm.reset();

      submitEvent.target.elements.newcontact_phone.value = "";
      submitEvent.target.elements.newcontact_email.value = "";
      submitEvent.target.elements.newcontact_name.value = "";
      submitEvent.target.elements.newcontact_company.value = "";
      submitEvent.target.elements.newcontact_type.value = "";
    },
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled || this.contact_modal_active || this.modalEditContact.show
          || this.modalConfirmDelete.show || this.modalConfirmDelete.show;
    },
    contacts_count: function() {
      return this.contacts.length;
    },
    getContactsFiltered: function() {
      if (this.contact_search_filter === "") {
        const ret = [...this.contacts];
        return ret.sort(sortContacts);
      }
      else {
        return this.contacts.filter(c =>
            c.name.toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || c.phone.toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || c.email.toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || c.company.toLowerCase().includes(this.contact_search_filter.toLowerCase())
        ).sort(sortContacts)
      }
    },
  },
  data() {
    return {
      contact_modal_active: false,
      modalEditContact: {
        show: false,
        id: "",
        current: {
          id: "",
          type: "",
          company: "",
          name: "",
          role: "",
          email: "",
          phone: "",
        },
      },
      modalConfirmDelete: {
        show: false,
        id: "",
      },
      contact_modal_type_options: [
        {
          id: "CUSTOMER_INDIVIDUAL",
          title: "Client — Particulier",
          subtitle: "Un particulier, client ou prospect",
          icon: "bi-person",
          bg: "bg-light-success",
          checked: true
        },
        {
          id: "CUSTOMER_PRO",
          title: "Client — Pro",
          subtitle: "Un professionnel, client ou prospect",
          icon: "bi-person-check",
          bg: "bg-light-info"
        },
        {
          id: "COMPANY",
          title: "Entreprise",
          subtitle: "Professionnel - Prestataire ou fournisseur",
          icon: "bi-building",
          bg: "bg-light-warning"
        },
        {
          id: "INTERNAL",
          title: "Interne",
          subtitle: "Quelqu'un de chez vous",
          icon: "bi-house-door",
          bg: "bg-light-primary"
        },
      ],
      contact_search_filter: "",
      contacts: [
        {
          id: "ct_01",
          contact_type: "client",
          company: "Kalm",
          name: "Bob - Kalm",
          role: "MOA",
          email: "bob@kalm.ai",
          phone: "+33 6 00 00 00 00",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>