<template>
  <!-- MAIN -->
  <div :class="modalActive ? 'blur-content' : ''">
    <ProjectBreadcrumbComponent
        title="Cockpit"
        current_route="project_home"
        :project_id=$route.params.id
    ></ProjectBreadcrumbComponent>
<!--    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card card-stretch mb-5 mb-xxl-8">
          &lt;!&ndash;begin::Header&ndash;&gt;
          <div class="card-header align-items-center border-0 mt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bold text-dark fs-3">Chronologie</span>
              <span class="text-muted mt-2 fw-semibold fs-6">Phases &amp; Jalons du projet</span>
            </h3>
          </div>
          &lt;!&ndash;end::Header&ndash;&gt;

          &lt;!&ndash;begin::Body&ndash;&gt;
          <div class="card-body pt-3">
            &lt;!&ndash;begin::Timeline&ndash;&gt;
            <div class="timeline-label">

              &lt;!&ndash;begin::Item&ndash;&gt;
              <div class="timeline-item">
                &lt;!&ndash;begin::Label&ndash;&gt;
                <div class="timeline-label fw-bold text-gray-800 fs-6">12/10</div>
                &lt;!&ndash;end::Label&ndash;&gt;

                &lt;!&ndash;begin::Badge&ndash;&gt;
                <div class="timeline-badge">
                  <i class="fa fa-genderless text-primary fs-1"></i>
                </div>
                &lt;!&ndash;end::Badge&ndash;&gt;

                &lt;!&ndash;begin::Text&ndash;&gt;
                <div class="timeline-content fw-mormal text-muted ps-3">
                  Début du projet
                </div>
                &lt;!&ndash;end::Text&ndash;&gt;
              </div>
              &lt;!&ndash;end::Item&ndash;&gt;

              &lt;!&ndash;begin::Item&ndash;&gt;
              <div class="timeline-item">
                &lt;!&ndash;begin::Label&ndash;&gt;
                <div class="timeline-label fw-bold text-gray-800 fs-6">20/10</div>
                &lt;!&ndash;end::Label&ndash;&gt;

                &lt;!&ndash;begin::Badge&ndash;&gt;
                <div class="timeline-badge">
                  <i class="fa fa-genderless text-gray fs-1"></i>
                </div>
                &lt;!&ndash;end::Badge&ndash;&gt;

                &lt;!&ndash;begin::Desc&ndash;&gt;
                <div class="timeline-content fw-semibold text-gray-800 ps-3">
                  Version finale du descriptif
                </div>
                &lt;!&ndash;end::Desc&ndash;&gt;
              </div>
              &lt;!&ndash;end::Item&ndash;&gt;

              &lt;!&ndash;begin::Item&ndash;&gt;
              <div class="timeline-item">
                &lt;!&ndash;begin::Label&ndash;&gt;
                <div class="timeline-label fw-bold text-gray-800 fs-6">02/12</div>
                &lt;!&ndash;end::Label&ndash;&gt;

                &lt;!&ndash;begin::Badge&ndash;&gt;
                <div class="timeline-badge">
                  <i class="fa fa-genderless text-muted fs-1"></i>
                </div>
                &lt;!&ndash;end::Badge&ndash;&gt;

                &lt;!&ndash;begin::Text&ndash;&gt;
                <div class="timeline-content fw-mormal text-muted ps-3">
                  Début du chantier
                </div>
                &lt;!&ndash;end::Text&ndash;&gt;
              </div>
              &lt;!&ndash;end::Item&ndash;&gt;

            </div>
            &lt;!&ndash;end::Timeline&ndash;&gt;
          </div>
          &lt;!&ndash;end: Card Body&ndash;&gt;
        </div>
      </div>
      <div class="col-12 col-md-6">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            card_subtitle="Dernière modification il y a 2 jours"
            card_text_line_1="7 ouvrages"
            card_text_line_2="3 fournitures"
            :card_progress_percent=70></ProgressCard>
      </div>
    </div>-->
    <div class="row g-5">
      <div class="col-12 mb-5">
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear"></i>Généralités</span>
            </h3>
          </div>
          <div class="card-body w-100 row pt-2">
            <div class="col-12 col-sm-6">
              <div class="form-group mb-3">
                <label class="text-muted">Nom du projet</label>
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="this.projectProperties.name">
              </div>
              <div class="form-group mb-3">
                <label class="text-muted">Nom de l'opération</label>
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="this.projectProperties.infoOpName">
              </div>
              <div class="form-group mb-3">
                <label class="text-muted">Adresse</label>
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="this.projectProperties.address">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group w-100 h-100 pb-9 mb-3">
                <label class="text-muted" >Compte-rendus — Informations importantes par défaut <i class="bi bi-info-circle" v-tooltip:bottom="`Code d\'immeuble, précaution importante... ce paragraphe sera la valeur par défaut de la rubrique &quot;Informations Importantes&quot; des comptes-rendus de chantier de ce projet. Vous pourrez la personnaliser sur chaque compte-rendu dans l\'application Kalm.`"></i><span></span></label>
                <textarea style="height:100%;" v-model="this.projectProperties.importantInfo" class="form-control form-control-lg form-control-solid w-100" type="text"></textarea>
              </div>
            </div>
            <div class="flex-column text-end">
              <button :disabled="this.isArchived" class="btn btn-primary btn-active-accent"
                      @click="this.updateProjectProperties()">
                Enregistrer
                <span v-if="this.buttonProjectPropertiesLoading" class="ms-1 spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-5">
      <div class="col-xl-12 mb-5">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch h-100 flex-grow-1">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 bi-person me-2"></i>Intervenants</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item align-middle">
                  <button :disabled="this.isArchived" class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder" @click="modalAssignContactShow()">+ Inviter</button>
                </li>
                <li class="nav-item align-middle">
                  <router-link :to="{name: 'contacts'}" class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">+ Créer</router-link>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0 mt-n3">
            <div class="tab-content mt-4" id="myTabTables2">
              <!--begin::Tap pane-->
              <div class="tab-pane fade show active" id="kt_tab_pane_2_1" role="tabpanel" aria-labelledby="kt_tab_pane_2_1">
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-borderless align-middle">
                    <thead>
                    <tr>
                      <th class="p-0 w-50px"></th>
                      <th class="p-0 min-w-150px"></th>
                      <th class="p-0 min-w-120px"></th>
                      <th class="p-0 min-w-70px"></th>
                      <th class="p-0 min-w-70px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="assignedContact in this.currentProject.contacts" :key="assignedContact.contact.id" class="h-100">
                      <td class="px-0 py-3">
                        <div class="symbol symbol-55px mt-1 me-5">
                                          <span class="symbol-label bg-light-primary align-items-end">
                                            <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg" class="mh-40px" />
                                          </span>
                        </div>
                      </td>
                      <td class="px-0">
                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">{{assignedContact.contact.company || assignedContact.contact.name}}</a>
                        <span v-if="assignedContact.contact.company" class="text-muted fw-bold d-block mt-1">{{ assignedContact.contact.phone }}</span>
                      </td>
                      <td></td>
                      <td class="text-center">
                        <span class="text-gray-800 fw-bolder d-block fs-6">{{ contact_role_options.find(o => o.id === assignedContact.role)?.name || "Entreprise" }}</span>
                      </td>
                      <td class="text-center">
                        <span class="">{{ assignedContact.contact.email }}</span>
                      </td>
                      <td class="text-end pe-0">
                        <button :disabled="this.isArchived" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                             @click="modalUnassignContactShow(assignedContact.contact.id)">
                          <span class="bi bi-x"></span>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Tap pane-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
    </div>
    <div class="row g-5">
      <div class="col-xl-6">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-grid"></i>Lots</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item align-middle">
                  <button :disabled="this.isArchived" class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder" @click="modalImportLotShow">+ Importer</button>
                </li>
                <li class="nav-item align-middle">
                  <button :disabled="this.isArchived" class="nav-link align-middle btn btn-active-light btn-color-muted py-2 ms-2 px-4 fw-bolder" @click="modalLotShow">+ Ajouter</button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div class="tab-content mt-4" id="myTabTables2">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="lot in currentProject.lots" :key="lot.id">
                    <td class="px-0" :class="lot.number.includes('.') ? 'ps-5' : ''">
                      <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                      <span class="text-muted fw-bold d-block mt-1">{{ lot.assignedCompany?.id ? lot.assignedCompany.company || lot.assignedCompany.name : "Non attribué"}}</span>
                    </td>
                    <td class="text-end w-100px pe-0">
                      <button :disabled="this.isArchived" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                              @click="modalEditLotShow(lot.id)">
                        <span class="bi bi-pencil-fill"></span>
                      </button>
                      <button :disabled="this.isArchived" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                              @click="modalConfirmDeleteLotShow(lot.id)">
                        <span class="bi bi-trash-fill"></span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
      <div class="col-xl-6">
        <!--begin::Table Widget 2-->
        <div class="card card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-pin"></i>Emplacements</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item align-middle">
                  <button :disabled="this.isArchived" @click="this.modalImportLocationShow()"
                          class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">
                    + Importer
                  </button>
                </li>
                <li class="nav-item align-middle">
                  <button :disabled="this.isArchived" @click="this.modalLocationShow()"
                          class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">
                    + Ajouter
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div class="tab-content mt-4" id="myTabTables2">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-50px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="location in currentProject.locations" :key="location.id">
                    <td class="px-0">
                      <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ location.name }}</a>
                    </td>
                    <td class="text-end w-100px pe-0">
                      <button :disabled="this.isArchived" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                              @click="modalEditLocationShow(location.id)">
                        <span class="bi bi-pencil-fill"></span>
                      </button>
                      <button :disabled="this.isArchived" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                              @click="modalConfirmDeleteLocationShow(location.id)">
                        <span class="bi bi-trash-fill"></span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Table Widget 2-->
      </div>
    </div>

  </div>
  <!-- ADD LOT -->
  <div class="modal"
       tabindex="-1"
       :style="modalLot.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalLotCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Numéro</label>
                        <input ref="lotNumberInput" type="text" pattern="\d+[\.\d+]*" v-model="modalLot.current.number" class="form-control form-control-lg form-control-solid" placeholder="0">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom du lot</label>
                        <input type="text" v-model="modalLot.current.name" class="form-control form-control-lg form-control-solid" placeholder="Généralités">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="fs-8 me-2 fw-bolder text-muted form-label">(Optionnel)</label>
                        <router-link :to="{name: 'contacts'}" class="fs-8 align-self-end form-label">+ Importer une nouvelle entreprise</router-link>
                        <select v-model="modalLot.current.assignedCompanyId" class="form-select form-select-solid">
                          <option v-for="assignedContact in this.currentProject.contacts" :key="assignedContact.contact.id"
                                  :value="assignedContact.contact.id"
                                  title="Format: 0.1.1">
                            {{ assignedContact.contact.company || assignedContact.contact.name }}
                          </option>
                        </select>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalLotCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalLotValidate" :disabled="!validateLotNumberInput(this.modalLot.current.number)" class="btn btn-lg btn-primary ms-2">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT LOT -->
  <div class="modal"
       tabindex="-1"
       :style="modalEditLot.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-1">
                        <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Numéro du lot</label>
                        <input type="number" pattern="\d+[\.\d+]*" v-model="modalEditLot.newLot.number"
                               class="form-control form-control-lg form-control-solid" placeholder="0">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom du lot</label>
                        <input type="text" v-model="modalEditLot.newLot.name" class="form-control form-control-lg form-control-solid" placeholder="Généralités">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="fs-8 me-2 fw-bolder text-muted form-label">(Optionnel)</label>
                        <router-link :to="{name: 'contacts'}" class="fs-8 align-self-end form-label">+ Importer une nouvelle entreprise</router-link>
                        <select v-model="modalEditLot.newLot.assignedCompanyId" class="form-select form-select-solid">
                          <option v-for="assignedContact in this.currentProject.contacts" :key="assignedContact.contact.id" :value="assignedContact.contact.id">{{ assignedContact.contact.company || assignedContact.contact.name }}</option>
                        </select>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalEditLotCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalEditLotValidate" class="btn btn-lg btn-primary ms-2">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONFIRM DELETE LOT -->
  <div class="modal"
       tabindex="-1"
       :style="modalConfirmDeleteLot.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalConfirmDeleteLotCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                        <p v-if="!canSafelyDeleteLot">Ce lot contient un ou plusieurs ouvrages dans le descriptif travaux. Pour le supprimer, retirez ces ouvrages ou déplacez-les dans d'autres lots.</p>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalConfirmDeleteLotCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalConfirmDeleteLotValidate"
                              :disabled="!canSafelyDeleteLot"
                              class="btn btn-lg btn-primary ms-2">
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ADD LOCATION -->
  <div class="modal"
       tabindex="-1"
       :style="modalLocation.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalLocationCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un emplacement</h3>
                      </div>
                      <!--end::Heading-->


                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom de l'emplacement</label>
                        <input type="text" v-model="modalLocation.current.name" class="form-control form-control-lg form-control-solid" placeholder="Chambre 01">
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalLocationCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalLocationValidate" class="btn btn-lg btn-primary ms-2">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONFIRM DELETE LOCATION -->
  <div class="modal"
       tabindex="-1"
       :style="modalConfirmDeleteLocation.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalConfirmDeleteLocationCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                      </div>
                      <p v-if="!canSafelyDeleteLocation">
                        Cet emplacement est sélectionné dans un ou plusieurs ouvrages du descriptif. Retirez-le de ces ouvrages pour pouvoir le supprimer définitivement.
                      </p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalConfirmDeleteLocationCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" :disabled="!canSafelyDeleteLocation" @click="modalConfirmDeleteLocationValidate" class="btn btn-lg btn-primary ms-2">
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- EDIT LOCATION -->
  <div class="modal"
       tabindex="-1"
       :style="modalEditLocation.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLocationCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier un emplacement</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom du lot</label>
                        <input type="text" v-model="modalEditLocation.newLocation.name" class="form-control form-control-lg form-control-solid" placeholder="Mon emplacement">
                      </div>
                      <!--end::Form Group-->

                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalEditLocationCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalEditLocationValidate" class="btn btn-lg btn-primary ms-2">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ASSIGN CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="modalAssignContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalAssignContactCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un intervenant depuis mes contacts</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Contact à ajouter</label>
                        <select v-model="modalAssignContact.current.contactId" class="form-select form-select-solid">
                          <option v-for="contact in [{id: '', name: 'Sélectionnez un contact...', disabled: true}, ...contacts]" :key="contact.id" :value="contact.id" :disabled="contact.disabled">{{ contact.company || contact.name }}</option>
                        </select>

                        <label class="fs-6 mt-5 me-2 fw-bolder text-dark form-label">Role</label>
                        <select v-model="modalAssignContact.current.role" class="form-select form-select-solid">
                          <option v-for="contact_role_option in contact_role_options" :key="contact_role_option.id" :value="contact_role_option.id">{{ contact_role_option.name }}</option>
                        </select>
                      </div>
                      <!--end::Form Group-->

                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalAssignContactCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button"
                              @click="modalAssignContactValidate"
                              :disabled="this.modalAssignContact.current.contactId === ''"
                              class="btn btn-lg btn-primary ms-2">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONFIRM UNASSIGN CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="modalUnassignContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalUnassignContactClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Retirer le contact du projet</h3>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalUnassignContactClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalUnassignContactValidate()" class="btn btn-lg btn-primary ms-2">
                        Confirmer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - Import from lot -->
  <div class="modal"
       tabindex="-1"
       :style="this.modals.importLot.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportLotClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div v-if="this.modals.importLot.page === 0"
                 class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                        <p class="text-muted">Choisissez le lot d'un projet ou gabarit dont vous voulez importer le contenu :</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Project Selection-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                        <select v-model="this.modals.importLot.projectId" class="form-select form-select-solid">
                          <option v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.getProjects]" :disabled="project.disabled" :key="project.id" :value="project.id">{{ project.name }}</option>
                        </select>
                      </div>
                      <!--end::Project Selection-->

                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalImportLotClose()">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2"
                              @click="this.modals.importLot.page++;"
                              :disabled="!this.modals.importLot.projectId"
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
            <!--begin::Stepper-->
            <div v-else
                 class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des lots</h3>
                        <p class="text-muted">Choisissez les lot que vous voulez importer :</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Project Selection-->
                      <div class="mb-10 w-100">
                        <div v-for="lot in this.modalImportLotFetch()"
                             :key="lot.id"
                             class="form-check form-check-custom mb-1">
                          <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.importLot.lots" :id="lot.id">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" :for="lot.id">
                            {{ lot.fullName }}
                          </label>
                        </div>
                      </div>
                      <!--end::Project Selection-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalImportLotClose()">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2"
                              @click="modalImportLotValidate()"
                              :disabled="!this.modals.importLot.projectId || !this.modals.importLot.lots"
                      >
                        Importer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - Import from location -->
  <div class="modal"
       tabindex="-1"
       :style="this.modals.importLocation.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportLocationClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div v-if="this.modals.importLocation.page === 0"
                 class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des emplacements</h3>
                        <p class="text-muted">Depuis un projet existant</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Project Selection-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                        <select v-model="this.modals.importLocation.projectId" class="form-select form-select-solid">
                          <option v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.getProjects]" :disabled="project.disabled" :key="project.id" :value="project.id">{{ project.name }}</option>
                        </select>
                      </div>
                      <!--end::Project Selection-->

                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalImportLocationClose()">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2"
                              @click="this.modals.importLocation.page++;"
                              :disabled="!this.modals.importLocation.projectId"
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
            <!--begin::Stepper-->
            <div v-else
                 class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactEditModalForm"
                     class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Importer des emplacements</h3>
                        <p class="text-muted">Choisissez les emplacements que vous voulez importer :</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Project Selection-->
                      <div class="mb-10 w-100">
                        <div v-for="location in this.modalImportLocationFetch()"
                             :key="location.id"
                             class="form-check form-check-custom mb-1">
                          <input class="form-check-input" type="checkbox" :value="location.id" v-model="this.modals.importLocation.locations" :id="location.id">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" :for="location.id">
                            {{ location.name }}
                          </label>
                        </div>
                      </div>
                      <!--end::Project Selection-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light"
                              @click="modalImportLocationClose()">
                        Annuler
                      </button>
                      <button class="btn btn-lg btn-primary ms-2"
                              @click="modalImportLocationValidate()"
                              :disabled="!this.modals.importLocation.projectId || !this.modals.importLocation.locations"
                      >
                        Importer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";

//import ProgressCard from "@/views/_core/components/ProgressCard";
import kalmStore from "@/store";
import {ALL_CONTACTS_QUERY} from "@/graphql/graphql";

export default {
  name: "ProjectHome",
  components: {
    ProjectBreadcrumbComponent,
    /*ProgressCard*/
  },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    }
  },
  data() {
    return {
      contacts: [],
      projects: [],
      lots: [],
      locations: [],
      modals: {
        importLot: {
          show: false,
          page: 0,
          projectId: '',
          lots: [],
        },
        importLocation: {
          show: false,
          page: 0,
          projectId: '',
          locations: [],
        }
      },
      modalLot: {
        show: false,
        default: {
          number: 0,
          name: "Nouveau Lot",
          assignedCompanyId: null,
        },
        current: {
          number: 0,
          name: "Nouveau Lot",
          assignedCompanyId: null,
        },
      },
      modalConfirmDeleteLot: {
        show: false,
        id: ""
      },
      modalEditLot: {
        show: false,
        newLot: {
          id: "",
          number: "",
          name: "",
          assignedCompanyId: null,
        },
      },
      modalLocation: {
        show: false,
        default: {
          name: "Nouvel Emplacement 01",
        },
        current: {
          name: "Nouvel Emplacement 01",
        },
      },
      modalConfirmDeleteLocation: {
        show: false,
        id: ""
      },
      modalEditLocation: {
        show: false,
        id: "",
        newLocation: {
          id: "",
          name: "",
        },
      },
      modalAssignContact: {
        show: false,
        default: {
          contactId: "",
          role: "CONTRACTOR",
        },
        current: {
          contactId: "",
          role: "CONTRACTOR",
        },
      },
      modalUnassignContact: {
        show: false,
        contactId: '',
      },
      contact_role_options: [
        {
          id: "MOA",
          name: "MOA - Maîtrise d'Ouvrage",
        },
        {
          id: "MOE",
          name: "MOE - Maîtrise d'Œuvre",
        },
        {
          id: "CONTRACTOR",
          name: "Entreprise",
        }
      ],
      editProject: {
        name: "",
        address: "",
        infoOpName: "",
        importantInfo: "",
        archived: "",
      },
      buttonProjectPropertiesLoading: false,
    }
  },
  methods: {
    getContactById(contactId) {
      return this.contacts.find(c => c.id === contactId);
    },
    getLotById(lotId) {
      return this.currentProject.lots.find(l => l.id === lotId);
    },
    getLocationById(locationId) {
      return this.currentProject.locations.find(l => l.id === locationId);
    },
    modalImportLotShow(){
      this.modals.importLot.show = true;
    },
    modalImportLotClose(){
      this.modals.importLot.show = false;
      this.modals.importLot.page = 0;
      this.modals.importLot.lots = [];
      this.modals.importLot.projectId = '';
    },
    modalImportLotFetch() {
      return kalmStore.getters.getProjectById(this.modals.importLot.projectId).lots
    },
    modalImportLotValidate(){
      this.modals.importLot.lots.forEach(importedlotId => {
        const importedLot = kalmStore.getters
            .getProjectById(this.modals.importLot.projectId)
            .lots.find(i => i.id === importedlotId);

        kalmStore.dispatch("addLotToFocusProject", {
          apolloClient: this.$apollo,
          number: importedLot.number,
          name: importedLot.name,
        });
      });

      this.modalImportLotClose();
    },
    modalImportLocationShow(){
      this.modals.importLocation.show = true;
    },
    modalImportLocationClose(){
      this.modals.importLocation.show = false;
      this.modals.importLocation.page = 0;
      this.modals.importLocation.locations = [];
      this.modals.importLocation.projectId = '';
    },
    modalImportLocationFetch() {
      return kalmStore.getters.getProjectById(this.modals.importLocation.projectId).locations;
    },
    modalImportLocationValidate(){
      this.modals.importLocation.locations.forEach(importedlocationId => {
        const importedLocation = kalmStore.getters
            .getProjectById(this.modals.importLocation.projectId)
            .locations.find(i => i.id === importedlocationId);

        kalmStore.dispatch("addLocationToFocusProject", {
          apolloClient: this.$apollo,
          name: importedLocation.name,
        });
      });

      this.modalImportLocationClose();
    },
    modalLotShow() {
      this.modalLot.current = {
        id: this.modalLot.default.id,
        name: this.modalLot.default.name,
        number: this.modalLot.default.number,
        assignedCompanyId: this.modalLot.default.assignedCompanyId,
      }
      this.modalLot.show = true;
    },
    modalLotCancel() {
      this.modalLot.current = this.modalLot.default;
      this.modalLot.show = false;
    },
    modalLotValidate() {
      const payload = {
        number: this.modalLot.current.number,
        name: this.modalLot.current.name,
        assignedCompany: this.modalLot.current.assignedCompanyId || null,
        apolloClient: this.$apollo,
      };

      kalmStore.dispatch("addLotToFocusProject", payload);

      this.modalLot.show = false;
    },
    modalConfirmDeleteLotShow(id) {
      this.modalConfirmDeleteLot.id = id
      this.modalConfirmDeleteLot.show = true;
    },
    modalConfirmDeleteLotValidate() {
      const lotId = this.modalConfirmDeleteLot.id;
      if(lotId){
        kalmStore.dispatch("deleteLot", {lotId, apolloClient: this.$apollo})
      }

      this.modalConfirmDeleteLot.id = "";
      this.modalConfirmDeleteLot.show = false;
    },
    modalConfirmDeleteLotCancel() {
      this.modalConfirmDeleteLot.show = false;
    },
    modalEditLotShow(id) {
      this.modalEditLot.id = id
      const oldLot = JSON.parse(JSON.stringify(this.getLotById(id)))
      this.modalEditLot.newLot = {
        id: oldLot.id,
        name: oldLot.name,
        number: oldLot.number,
        assignedCompanyId: oldLot.assignedCompany?.id || null
      }
      this.modalEditLot.show = true;
    },
    modalEditLotValidate() {
      const lotId = this.modalEditLot.id;

      const newLot = {
        id: lotId,
        number: this.modalEditLot.newLot.number || "",
        name: this.modalEditLot.newLot.name || "",
        assignedCompany: this.modalEditLot.newLot.assignedCompanyId || null,
      }

      if(lotId){
        kalmStore.dispatch("editLot", {lotId, newLot, apolloClient: this.$apollo})
      }

      this.modalEditLot.id = "";
      this.modalEditLot.show = false;
    },
    modalEditLotCancel() {
      this.modalEditLot.show = false;
      this.modalEditLot.id = "";
      this.modalEditLot.newLot = "";
    },
    modalConfirmDeleteLocationShow(id) {
      this.modalConfirmDeleteLocation.id = id
      this.modalConfirmDeleteLocation.show = true;
    },
    modalConfirmDeleteLocationValidate() {
      const locationId = this.modalConfirmDeleteLocation.id;
      if(locationId){
        kalmStore.dispatch("deleteLocation", {locationId, apolloClient: this.$apollo})
      }

      this.modalConfirmDeleteLocation.id = "";
      this.modalConfirmDeleteLocation.show = false;
    },
    modalConfirmDeleteLocationCancel() {
      this.modalConfirmDeleteLocation.show = false;
    },
    modalLocationShow() {
      this.modalLocation.current.name = this.modalLocation.default.name;
      this.modalLocation.show = true;
    },
    modalLocationCancel() {
      this.modalLocation.current = this.modalLocation.default;
      this.modalLocation.show = false;
    },
    modalLocationValidate() {
      const payload = {
        number: this.modalLocation.current.number,
        name: this.modalLocation.current.name,
        apolloClient: this.$apollo,
      };

      kalmStore.dispatch("addLocationToFocusProject", payload);

      this.modalLocation.show = false;
    },
    modalEditLocationShow(id) {
      this.modalEditLocation.id = id
      this.modalEditLocation.newLocation = JSON.parse(JSON.stringify(this.getLocationById(id)));
      this.modalEditLocation.show = true;
    },
    modalEditLocationValidate() {
      const locationId = this.modalEditLocation.id;

      const newLocation = {
        id: locationId,
        name: this.modalEditLocation.newLocation.name || "",
      }

      if(locationId){
        kalmStore.dispatch("editLocation", {locationId, newLocation, apolloClient: this.$apollo})
      }

      this.modalEditLocation.id = "";
      this.modalEditLocation.show = false;
    },
    modalEditLocationCancel() {
      this.modalEditLocation.show = false;
      this.modalEditLocation.id = "";
      this.modalEditLocation.newLocation = "";
    },
    modalAssignContactShow() {
      this.modalAssignContact.show = true;
    },
    modalAssignContactValidate() {
      this.modalAssignContact.show = false;

      kalmStore.dispatch('assignContact', {
        apolloClient: this.$apollo,
        contactId: this.modalAssignContact.current.contactId,
        role: this.modalAssignContact.current.role,
      });
    },
    modalAssignContactCancel() {
      this.modalAssignContact.show = false;
      this.modalAssignContact.current = this.modalAssignContact.default;
    },
    validateLotNumberInput(input) {
      let ret = true
      if(input) {
        ret = true
      }
      return ret
    },
    modalUnassignContactShow(contactId){
      this.modalUnassignContact.show = true;
      this.modalUnassignContact.contactId = contactId;
    },
    modalUnassignContactClose(){
      this.modalUnassignContact.show = false;
      this.modalUnassignContact.contactId = '';
    },
    modalUnassignContactValidate(){
      const contactId = this.modalUnassignContact.contactId;
      const apolloClient = this.$apollo;
      if(contactId) kalmStore.dispatch("unassignContact", { contactId, apolloClient });
      this.modalUnassignContactClose();
    },
    updateProjectProperties(){
      this.buttonProjectPropertiesLoading = true;

      setTimeout(() => {
        this.buttonProjectPropertiesLoading = false;
      }, 350);

      const apolloClient = this.$apollo;
      const newProperties = {
        name: this.projectProperties.name,
        archived: this.projectProperties.archived,
        importantInfo: this.projectProperties.importantInfo,
        infoOpName: this.projectProperties.infoOpName,
        address: this.projectProperties.address,
      }

      console.log(newProperties)
      kalmStore.dispatch("updateProjectProperties", {apolloClient, newProperties})
    }
  },
  computed: {
    modalActive() {
      return kalmStore.state.display.profileToggled
          || this.modalLot.show || this.modalLocation.show
          || this.modalAssignContact.show || this.modalUnassignContact.show
          || this.modalEditLot.show || this.modalEditLocation.show
          || this.modals.importLot.show || this.modals.importLocation.show
          || this.modalConfirmDeleteLot.show || this.modalConfirmDeleteLocation.show;
    },
    currentProject() {
      const projectId = kalmStore.state.display.currentProjectFocus;
      return kalmStore.getters.getProjectById(projectId);
    },
    isArchived() {
      return this.currentProject.archived;
    },
    getProjects() {
      return kalmStore.state.description.projects;
    },
    projectProperties: {
      get() {
        return {
          name: this.editProject.name || this.currentProject.name,
          address: this.editProject.address || this.currentProject.address,
          infoOpName: this.editProject.infoOpName || this.currentProject.infoOpName,
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
          archived: this.editProject.archived,
        }
      },
      set(newValue) {
        this.editProject = newValue;
      }
    },
    canSafelyDeleteLot() {
      return kalmStore.getters.canSafelyDeleteLot(kalmStore.state.display.currentProjectFocus, this.modalConfirmDeleteLot.id);
    },
    canSafelyDeleteLocation() {
      console.log(kalmStore.getters.canSafelyDeleteLocation(kalmStore.state.display.currentProjectFocus, this.modalConfirmDeleteLocation.id))
      return kalmStore.getters.canSafelyDeleteLocation(kalmStore.state.display.currentProjectFocus, this.modalConfirmDeleteLocation.id);
    },
  },
}
</script>

<style scoped>
</style>