
export const displayModule = {
    state: () => ({
        asideToggled: false,
        notificationToggled: false,
        profileToggled: false,
        currentProjectFocus: "0",
        currentProjectFocusName: "0",
    }),
    mutations: {
        toggleAside(state) {
            state.asideToggled = !state.asideToggled;
        },
        toggleNotification(state) {
            state.notificationToggled = !state.notificationToggled;
        },
        toggleProfile(state) {
            state.profileToggled = !state.profileToggled;
        },
        setCurrentProjectFocus(state, payload) {
            let id = payload.projectId;
            let name = payload.projectName;

            if(id === undefined || id === null) {
                id = "0";
                name = "0";
            }

            state.currentProjectFocus = id;
            state.currentProjectFocusName = name;
        },
    },
    getters: {
        currentProjectFocus(state) {
           return state.currentProjectFocus
        },
    }
}
