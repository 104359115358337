<template>
  <div style="z-index: 10000 !important;">
    <span :class="this.open ? 'bg-blue' : ''">
      <div ref="input"
       class="form-select fw-normal text-gray-800 py-1 fs-6 field-multilocation"
       style="z-index: 10"
       :class="this.open ? 'rounded-bottom-0' : ''"
       @click="this.openClose" :value="selectedLabels">
        <div v-for="tag in this.value" :key="tag" class="d-inline-block bg-primary text-white px-3 m-1 rounded">
          <span>{{ this.options.find(l => l.id === tag)?.name }}</span>
          <i class="bi bi-x ms-2 text-white align-middle cursor-pointer" @click="removeValue($event, tag)"></i>
        </div>
        <div v-if="this.value.length === 0" class="fs-6 py-1">Aucun emplacement sélectionné</div>
      </div>
    </span>
    <span v-if="this.open" :style="`position: absolute; z-index: 10000 !important; min-width: ${multiselectWidth}px;`">

      <ul ref="dropdown" class="w-100 list-unstyled bg-white shadow rounded-bottom" style="z-index: 200 !important;">
        <li class="p-2 input-group" v-if="this.options.filter(o => !this.value.includes(o.id)).length !== 0">
          <label class="input-group">Rechercher parmi les emplacement</label>
          <input type="text" class="form-control input-group rounded-start fw-normal" v-model="this.multiselectSearch">
        </li>
        <li v-for="(option, index) in this.options.filter(o => !this.value.includes(o.id)).filter(o => o.name.toUpperCase().includes(this.multiselectSearch.toUpperCase()))"
            style="z-index: 10000 !important;"
            :key="option.id"
            class="p-2 text-gray-800 fs-6 px-5 py-3 bg-hover-secondary"
            :class="index === this.options.filter(o => !this.value.includes(o.id)).length-1 ? 'rounded-bottom' : ''"
            @click="addValue($event, option.id)">
          {{ option.name }}
        </li>
        <li v-if="this.options.filter(o => !this.value.includes(o.id)).length === 0"
            class="p-2 ps-5 bg-white fs-6 rounded-bottom"
            style="z-index: 10000 !important;"
            @click="addValue($event, option.id)">
          Tous les emplacements ont été ajoutés
        </li>
      </ul>
    </span>
  </div>
</template>

<script>
export default {
  name: "LocationMultiselect",
  props: {
    modelValue: Array,
    options: Array,
  },
  emits: ['update:modelValue'],
  mounted(){
    this.multiselectWidth = this.$refs.input.offsetWidth;
    document.addEventListener('click', this.close)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close)
  },
  data: () => ({
    open: false,
    multiselectWidth: 0,
    multiselectSearch: "",
  }),
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)){
        this.open = false
      }
      event.stopPropagation();
      event.preventDefault();
    },
    openClose(){
      this.open = !this.open;
    },
    addValue(event, id) {
      this.value = [...this.value, id].sort(this.sortValue);
      if(this.options.filter(o => !this.value.includes(o.id)).length === 0) {
        this.open = false;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    removeValue(event, id) {
      this.value = [...this.value.filter(l => l !== id)];
      event.stopPropagation();
      event.preventDefault();
    },
    sortValue(a,b) {
      if(this.options.find(l => l.id === a)?.name < this.options.find(l => l.id === b)?.name ) {
        return -1
      }
      else if(this.options.find(l => l.id === a)?.name > this.options.find(l => l.id === b)?.name ) {
        return 1
      }
      else {
        return 0
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    selectedLabels: {
      get() {
        return [...new Set(this.value)].map(v => this.options.find(l => l.id === v)?.name).join(', ')
      }
    }
  },
}
</script>

<style scoped>

</style>