<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
          title="Consultation"
          current_route="project.tender.scenarios"
          :project_id=$route.params.id
          :options="[{name: 'Suivi', id: 'project.tender.manage'},{name: 'Comparatif', id: 'project.tender.table'},{name: 'Scénarios', id: 'project.tender.scenarios'},]"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <div class="row print-hidden" >
      <div class="col-12">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            :card_text_line_1="'' + this.card_count_proposals_sent + ((this.card_count_proposals_sent !== 1) ? ' descriptifs envoyés' : ' descriptif envoyé')"
            :card_text_line_2="'' + this.card_count_proposals_received + ((this.card_count_proposals_received !== 1) ? ' chiffrages reçus' : ' chiffrage reçu')"
            :card_progress_percent=(card_count_proposals_sent+card_count_proposals_received)/this.card_count_proposals_created*50>
        </ProgressCard>
      </div>
    </div>
    <PrintHeader pageTitle="Consultation des entreprises" sectionTitle="Scénarios d'attribution des lots"></PrintHeader>
    <!--end::Row-->
    <h2 class="text-dark fw-bold fs-1 mb-5 print-hidden">Scénarios d'attribution</h2>
    <!--begin::Row-->
    <div class="row col-12 g-0">
      <!--begin::Card-->
      <div class="card container px-2">
        <div class="card-toolbar py-2 w-100 text-end print-hidden">
          <button type="button"
                  @click="this.printDocument()"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-download"> Télécharger</span></button>
          <button type="button"
                  @click="this.modalCreateScenarioShow()"
                class="btn me-1 btn-active-light btn-color-muted fw-bolder">+ Ajouter un scénario</button>
        </div>
        <div class="card-body pb-5 d-flex">
          <!--begin::Table-->
          <table class="table h-1px mb-0" style="table-layout: fixed; word-wrap: break-word;">
            <!--begin::head-->
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0">
              <th class=""></th>
              <th v-for="scenario in this.scenarios"
                  :key="scenario.id"
                  class="h-100 w-150px px-2 py-0">
                <div class="rounded-top overflow-hidden h-100 w-100 p-4 border border-primary border-bottom-0 text-center text-white bg-primary">
                  {{ scenario.name }}
                </div>
              </th>
            </tr>
            </thead>
            <!--end::head-->

            <!--begin::body-->
            <tbody class="h-100">
            <!--begin::emptyRow-->
            <tr class="h-100 bg-white">
              <td class="h-100"></td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell h-100 px-2 py-0">
                <div class="h-100 w-100 border-start border-end border-1 border-primary text-center"></div>
              </td>
            </tr>
            <!--end::emptyRow-->

            <!--begin::tableContent-->
            <template v-for="lot in this.lots" :key="lot.id">
              <!--begin:lotHeader-->
              <tr class="h-100 lot-header">
                <td class="py-2 h-100">
                  <div class="d-flex print-noindent" :class="lot.number.includes('.') ? 'ms-3' : ''">
                    <h3 @click="this.toggleLot(lot.id)"
                        class="cursor-pointer align-self-center">
                      <span>
                        <i class="bi me-2" :class="this.lotToggled(lot.id) ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"></i>
                      </span>
                      {{ lot.fullName }}
                    </h3>
                  </div>
                </td>
                <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell px-2 py-0 h-100">
                  <div class="h-100 w-100 d-flex flex-column justify-content-center border-start border-end border-1 border-primary text-center text-dark fw-bold">
                    <div v-if="this.getProposalForScenarioLot(scenario.id, lot.id)" class="align-self-center pt-2">{{ this.proposalSubtotal(this.getProposalForScenarioLot(scenario.id, lot.id).id, lot.id)}}</div>
                    <div v-else class="align-self-center pt-2">—</div>
                    <div v-if="this.getProposalForScenarioLot(scenario.id, lot.id)" class="align-self-center text-gray-600 fw-light">{{ this.getProposalForScenarioLot(scenario.id, lot.id).contact.company || this.getProposalForScenarioLot(scenario.id, lot.id).contact.name }}</div>
                    <div v-else class="align-self-center text-gray-600 fw-light">Non attribué</div>
                  </div>
                </td>
              </tr>
              <!--end:lotHeader-->
              <!--begin:lotContent-->
              <template v-if="this.lotToggled(lot.id)">
                <tr v-for="chiffrage in this.proposals[0]?.chiffrages.filter(c => c.lot.id === lot.id).sort(function(a, b) {
                      if (a.order < b.order) { return -1; }
                      if (a.order > b.order) { return 1; }
                      return 0;
                    })" :key="chiffrage.ouvrageId" class="h-100 lot-content">
                  <td class="py-0">
                    <p>{{ chiffrage.title }}<span @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                                                  @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                                                  class="ms-2 bi bi-info-circle text-muted">
                        <span v-if="chiffrage.ouvrageId === this.tooltip" class="ms-2">
                          <span>{{ chiffrage.description }}</span>
                          <span v-if="chiffrage.qt"> — Qt: {{ chiffrage.qt }}</span>
                          <span v-if="chiffrage.location?.name"> — {{ chiffrage.location?.name }}</span>
                        </span>
                      </span></p>
                  </td>
                  <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell px-2 py-0 h-100">
                    <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;">
                      <span v-if="this.getProposalForScenarioLot(scenario.id, lot.id)">{{ parseFloat(this.getProposalForScenarioLot(scenario.id, lot.id).chiffrages.find(c => c.ouvrageId === chiffrage.ouvrageId)?.value || "0") + " € HT"}}</span>
                      <span v-else>—</span>
                    </div>
                  </td>
                </tr>
              </template>
              <!--end:lotContent-->
            </template>
            <!--end::tableContent-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">Total HT</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-middle px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.scenarioTotal(scenario.id) }} € HT
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">TVA</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-text-bottom px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ this.scenarioVatTotal(scenario.id) }} €
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:total-->
            <tr class="h-100 lot-header">
              <td class="text-end">Total TTC</td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="d-table-cell align-middle px-2 py-0 h-100">
                <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                  <div class="align-self-center">
                    {{ (parseFloat(this.scenarioTotal(scenario.id)) + parseFloat(this.scenarioVatTotal(scenario.id))).toFixed(2) }} € TTC
                  </div>
                </div>
              </td>
            </tr>
            <!--end:total-->

            <!--begin:roundedBottom-->
            <tr>
              <td></td>
              <td v-for="scenario in this.scenarios" :key="scenario.id" class="px-2 py-0 h-100">
                <div @click="modalUpdateScenarioShow(scenario.id)"
                     class="edit-proposal-button rounded-bottom h-100 w-100 border border-primary border-top-0 text-center text-white bg-primary p-2">
                  <i class="bi print-hidden bi-pencil-fill text-white"></i>
                </div>
              </td>
            </tr>
            <!--end:roundedBottom-->
            </tbody>
            <!--end::body-->
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Content-->

  <!--begin::Modal Create Scenario-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalCreateScenarioClose()"
       :style="this.modals.createScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalCreateScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-600px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Ajouter un scénario d'attribution</h3>
                      </div>
                      <div>
                        <label>Nom du scénario</label>
                        <input class="form-control form-control-lg form-control-solid" v-model="this.modals.createScenario.name">
                      </div>
                      <div class="">
                        <div v-for="lot in this.lots" :key="lot.id" class="mt-4">
                          <label>{{ lot.fullName }} — Entreprise attribuée</label>
                          <select v-model="this.modals.createScenario.graph[lot.id]" class="form-select form-select-lg form-select-solid fw-bold">
                            <option v-for="proposal in [...this.proposals, {id: '', contact: {name: 'Lot non attribué'}}]" :value="proposal.id" :key="proposal.id">{{ proposal.contact.company || proposal.contact.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalCreateScenarioClose()" class="me-2 btn btn-lg btn-light-primary btn-active-accent">
                        Annuler
                      </button>
                      <button type="button"
                              @click="this.modalCreateScenarioValidate()"
                              :disabled="Object.keys(this.modals.createScenario.graph).length !== this.lots.length
                                          || !this.modals.createScenario.name"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Modal Update Scenario-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalUpdateScenarioClose()"
       :style="this.modals.updateScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalUpdateScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-600px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier le scénario d'attribution</h3>
                      </div>
                      <div>
                        <label>Nom du scénario</label>
                        <input class="form-control form-control-lg form-control-solid" v-model="this.modals.updateScenario.name">
                      </div>
                      <div class="">
                        <div v-for="lot in this.lots" :key="lot.id" class="mt-4">
                          <label>{{ lot.fullName }} — Entreprise attribuée</label>
                          <select v-model="this.modals.updateScenario.graph[lot.id]" class="form-select form-select-lg form-select-solid fw-bold">
                            <option v-for="proposal in [...this.proposals, {id: '', contact: {name: 'Lot non attribué'}}]" :value="proposal.id" :key="proposal.id">{{ proposal.contact.company || proposal.contact.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalDeleteScenarioShow(this.modals.updateScenario.scenarioId)" class="me-2 btn btn-lg btn-outline-danger btn-active-bg-accent btn-active-color-danger">
                        Supprimer
                      </button>
                      <button type="button" @click="this.modalUpdateScenarioClose()" class="me-2 btn btn-lg btn-light-primary btn-active-accent">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUpdateScenarioValidate()" class="btn btn-lg btn-primary btn-active-light-primary">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CONFIRM DELETE SCENARIO -->
  <div class="modal"
       tabindex="-1"
       :style="modals.deleteScenario.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalDeleteScenarioClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Supprimer le scénario ?</h3>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalDeleteScenarioClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalDeleteScenarioValidate()" class="btn btn-lg btn-primary ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCard from "@/views/_core/components/ProgressCard";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import kalmStore from "@/store";
import {
  ALL_CONTACTS_QUERY,
  CURRENT_USER_QUERY,
  GET_PROPOSALS_FOR_PROJECT,
  GET_SCENARIOS_FOR_PROJECT
} from "@/graphql/graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";

export default {
  name: "ScenarioPage",
  components: { ProgressCard, ProjectBreadcrumbComponent, PrintHeader },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    }
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled
          || this.modals.createScenario.show
          || this.modals.updateScenario.show
          || this.modals.deleteScenario.show;
    },
    lots(){
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).lots
    },
    locations(){
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).locations
    },
    proposals() {
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).sort()
    },
    scenarios() {
      return kalmStore.getters.getScenariosForProject(kalmStore.getters.currentProjectFocus).sort()
    },
    storeIsReady() {
      return kalmStore.getters.currentProjectFocus
          && kalmStore.getters.currentProjectFocus !== "0"
          && kalmStore.getters.currentProjectFocus !== 0
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus;
    },
    card_count_proposals_created(){
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).length
    },
    card_count_proposals_sent(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
  },
  methods: {
    printDocument() {
      window.print();
    },
    modalCreateScenarioShow(){
      this.modals.createScenario.graph = {};
      this.modals.createScenario.name = "";
      this.modals.createScenario.show = true;
    },
    modalCreateScenarioClose(){
      this.modals.createScenario.show = false;
      this.modals.createScenario.name = "";
      this.modals.createScenario.graph = {};
      this.modals.createScenario.scenarioId = "";
    },
    modalCreateScenarioValidate(){
      const apolloClient = this.$apollo;
      const projectId = this.currentProjectFocus;
      const name = this.modals.createScenario.name;
      const graph = JSON.stringify(this.modals.createScenario.graph);
      kalmStore.commit("createScenario", {projectId, name, graph, apolloClient} );
      this.modalCreateScenarioClose();
    },
    modalUpdateScenarioShow(scenarioId){
      this.modals.updateScenario.scenarioId = scenarioId;
      this.modals.updateScenario.name = this.getScenarioById(scenarioId).name;
      this.modals.updateScenario.graph = JSON.parse(this.getScenarioById(scenarioId).graph);
      this.modals.updateScenario.show = true;
    },
    modalUpdateScenarioClose(){
      this.modals.updateScenario.show = false;
      this.modals.updateScenario.name = "";
      this.modals.updateScenario.graph = {};
      this.modals.updateScenario.scenarioId = "";
    },
    modalUpdateScenarioValidate(){
      const apolloClient = this.$apollo;
      const name = this.modals.updateScenario.name;
      const scenarioId = this.modals.updateScenario.scenarioId;
      const graph = JSON.stringify(this.modals.updateScenario.graph);
      kalmStore.commit("updateScenario", {scenarioId, name, graph, apolloClient} );
      this.modalUpdateScenarioClose();
    },
    modalDeleteScenarioShow(scenarioId) {
      this.modals.deleteScenario.show = true;
      this.modals.updateScenario.show = false;
      this.modals.deleteScenario.scenarioId = scenarioId;
    },
    modalDeleteScenarioClose() {
      this.modals.deleteScenario.show = false;
      this.modals.updateScenario.show = true;
    },
    modalDeleteScenarioValidate() {
      this.modals.deleteScenario.show = false;
      const apolloClient = this.$apollo;
      const scenarioId = this.modals.deleteScenario.scenarioId;
      kalmStore.commit("deleteScenario", {scenarioId, apolloClient});
      this.modals.deleteScenario.scenarioId = "";
    },
    getProposalById(proposalId){
      return this.proposals.find(p => p.id === proposalId);
    },
    getScenarioById(scenarioId){
      return kalmStore.getters.getScenarioById(scenarioId);
    },
    getProposalForScenarioLot(scenarioId, lotId){
      return this.getProposalById(JSON.parse(this.getScenarioById(scenarioId).graph)[lotId]);
    },
    proposalSubtotal(proposalId, lotId) {
      try {
        return this.getProposalById(proposalId)
            .chiffrages.filter(c => c.lot.id === lotId)
            .map(c => {
              const ret = parseFloat(c?.value)
              return isNaN(ret) ? 0 : parseFloat(c?.value)
            })
            .reduce((a,b) => a + b, 0)
            .toFixed(2) + " € HT";
      } catch (e) {
        return "O.00 € HT"
      }
    },
    proposalTotal(proposalId) {
      return kalmStore.getters.getProposalById(proposalId)
          .chiffrages
          .map(c => {
            const ret = parseFloat(c?.value)
            return isNaN(ret) ? 0 : parseFloat(c?.value)
          })
          .reduce((a,b) => a + b, 0)
          .toFixed(2);
    },
    scenarioTotal(scenarioId) {
      let totalHT = 0;
      const graph = JSON.parse(this.getScenarioById(scenarioId).graph);

      this.lots.forEach(lot => {
        const proposalForLot = graph[lot.id]
        const subTotal = parseFloat(this.proposalSubtotal(proposalForLot, lot.id)) || 0
        totalHT += subTotal
      })

      return totalHT.toFixed(2);
    },
    scenarioVatTotal(scenarioId) {
      let totalVAT = 0;
      const graph = JSON.parse(this.getScenarioById(scenarioId).graph);

      this.lots.forEach(lot => {
        const proposalForLot = graph[lot.id]
        const subTotal = parseFloat(this.proposalVatSubtotal(proposalForLot, lot.id)) || 0
        totalVAT += subTotal
      })

      return totalVAT.toFixed(2);
    },
    proposalVatSubtotal(proposalId, lotId) {
      const chiffrages = kalmStore.getters.getProposalById(proposalId)?.chiffrages;

      if(chiffrages) {
        return kalmStore.getters.getProposalById(proposalId)
            .chiffrages.filter(c => c.lot.id === lotId)
            .map(c => parseFloat(c?.vatValue))
            .reduce((a,b) => a + b, 0);
      } else {
        return 0;
      }

    },
    proposalVatTotal(proposalId) {
      return kalmStore.getters.getProposalById(proposalId)
          .chiffrages.map(c => parseFloat(c?.vatValue))
          .reduce((a,b) => a + b, 0)
          .toFixed(2);
    },
    toggleLot(lotId) {
      if(this.toggledLots.includes(lotId)) {
        this.toggledLots = [...this.toggledLots.filter(l => l !== lotId)]
      }
      else {
        this.toggledLots.push(lotId)
      }
    },
    lotToggled(lotId) {
      return this.toggledLots.includes(lotId)
    },
    showOuvrageInfoTooltip(ouvrageId, status){
      this.tooltip = status ? ouvrageId : '';
    },
  },
  data() {
    return {
      lines: [],
      contacts: [],
      modals: {
        updateScenario: {
          show: false,
          scenarioId: "",
          graph: {},
          name: "",
        },
        createScenario: {
          show: false,
          graph: {},
          name: "",
        },
        deleteScenario: {
          show: false,
          scenarioId: "",
        }
      },
      tooltip: '',
      toggledLots: []
    }
  },
  async mounted() {

    if((kalmStore.getters.currentProjectFocus
        && kalmStore.getters.currentProjectFocus !== "0"
        && kalmStore.getters.currentProjectFocus !== 0)){

      const queryScenarios = await this.$apollo.query({
        query: GET_SCENARIOS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      queryScenarios.data.scenarios.forEach( (newScenario) => {
        kalmStore.commit("initializeScenario", { newScenario })
      })

      const queryProposals = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      queryProposals.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  },
  watch: {
    'currentProjectFocus': async function() {
      const queryScenarios = await this.$apollo.query({
        query: GET_SCENARIOS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      queryScenarios.data.scenarios.forEach( (newScenario) => {
        kalmStore.commit("initializeScenario", { newScenario })
      })

      const queryProposals = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      queryProposals.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  }
}
</script>

<style scoped>
  .blur-content{
    filter: blur(5px);
  }

  .edit-proposal-button {
    cursor: pointer;
  }
  .edit-proposal-button:hover, .edit-proposal-button:hover i {
    cursor: pointer;
    background-color: white !important;
    color: gray !important;
  }

  @media print {
    .print-hidden {
      display: none;
    }

    .print-noindent {
      margin-left: 0 !important;
    }

    tr, th {
      break-inside: avoid !important;
    }

    table {
      break-before: avoid;
    }
  }
</style>