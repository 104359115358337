import { createApp, h } from "vue";
import App from "@/App";

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

import kalmRouter from "@/router/index.js";
import kalmStore from "@/store";
import {Tooltip} from "bootstrap";


fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
    .then(response => response.json())
    .then((data) => {
        if(!data.isAuthenticated) {
            window.location.href = process.env.VUE_APP_URL_BACK+"/auth/login";
        }
    })

// HTTP connection to the API
const link = createHttpLink({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_URL_BACK+"/auth/graphql",
    credentials: 'include'
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const kalmApolloClient = new ApolloClient({
    link,
    cache,
})

const kalmApolloProvider = createApolloProvider({
    defaultClient: kalmApolloClient,
})

const tooltipDirective = {
    mounted(el, binding) {
        el.setAttribute('data-toggle', 'tooltip')
        el.classList.add("tooltip-inverse");

        new Tooltip(el,{
            title: binding.value,
            placement: binding.arg,
            trigger: 'hover'

        })
    }
}


createApp({
    render: () => h(App),
})
    // load apollo
    .use(kalmApolloProvider)
    // load router & store
    .use(kalmRouter)
    .use(kalmStore)
    .directive('tooltip', tooltipDirective)
    // mount app
    .mount("#app")