<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <div class="print-hidden">
      <ProjectBreadcrumbComponent
          title="Consultation"
          current_route="project.tender.table"
          :project_id=$route.params.id
          :options="[{name: 'Suivi', id: 'project.tender.manage'}, {name: 'Comparatif', id: 'project.tender.table'}, {name: 'Scénarios', id: 'project.tender.scenarios', disabled: !this.currentUser?.groups?.includes('plan_premium')},]"
      ></ProjectBreadcrumbComponent>
    </div>
    <!--begin::Row-->
    <div class="row print-hidden">
      <div class="col-12">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            :card_text_line_1="'' + this.card_count_proposals_sent + ((this.card_count_proposals_sent !== 1) ? ' descriptifs envoyés' : ' descriptif envoyé')"
            :card_text_line_2="'' + this.card_count_proposals_received + ((this.card_count_proposals_received !== 1) ? ' chiffrages reçus' : ' chiffrage reçu')"
            :card_progress_percent=(card_count_proposals_sent+card_count_proposals_received)/this.card_count_proposals_created*50>
        </ProgressCard>
      </div>
    </div>
    <PrintHeader pageTitle="Consultation des entreprises" sectionTitle="Synthèse comparative"></PrintHeader>
    <!--end::Row-->
    <h2 class="text-dark fw-bold fs-1 mb-5 print-hidden">Devis reçus</h2>
    <!--begin::Row-->
    <div class="row col-12 g-0">
      <!--begin::Card-->
      <div class="card container px-2">
        <div class="card-toolbar py-2 w-100 text-end print-hidden">
          <button type="button"
                  @click="this.printDocument()"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-download"></span> Télécharger</button>
          <button type="button"
                  @click="this.$router.push({name: 'project.tender.manage'})"
                  class="btn me-1 btn-active-light btn-color-muted fw-bolder"><span class="bi align-baseline bi-plus"></span> Ajouter un devis</button>
        </div>
        <div class="card-body pb-5 d-flex">
          <!--begin::Table-->
          <table class="table h-1px mb-0">
            <!--begin::head-->
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 h-100 p-0">
              <th class=""></th>
              <th class=""></th>
              <th v-for="proposal in this.proposals"
                  :key="proposal.contact.id"
                  class="h-100 w-150px px-2 py-0">
                <div class="rounded-top h-100 w-100 p-4 border border-primary border-bottom-0 text-center text-white bg-primary">
                  {{ proposal.contact.company || proposal.contact.name }}
                </div>
              </th>
            </tr>
            </thead>
            <!--end::head-->

            <!--begin::body-->
            <tbody class="h-100">
              <!--begin::emptyRow-->
              <tr class="h-100 bg-white">
                <td class="h-100"></td>
                <td class="h-100"></td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell h-100 px-2 py-0">
                  <div class="h-100 w-100 border-start border-end border-1 border-primary text-center"></div>
                </td>
              </tr>
              <!--end::emptyRow-->

              <!--begin::tableContent-->
              <template v-for="lot in this.lots" :key="lot.id">
                <!--begin:lotHeader-->
                <tr class="h-100 lot-header">
                  <td class="py-2 h-100">
                    <div class="d-flex print-noindent" :class="lot.number.includes('.') ? 'ms-3' : ''">
                      <h3 @click="this.toggleLot(lot.id)"
                          class="cursor-pointer align-self-center">
                      <span>
                        <i class="bi me-2" :class="this.lotToggled(lot.id) ? 'bi-arrow-down text-primary' : 'bi-arrow-right'"></i>
                      </span>
                        {{ lot.fullName }}
                      </h3>
                    </div>
                  </td>
                  <td class="py-1 h-100"></td>
                  <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell px-2 py-0 h-100">
                    <div class="h-100 w-100 d-flex justify-content-center border-start border-end border-1 border-primary text-center text-dark fw-bold">
                      <div class="align-self-center">{{ this.proposalSubtotal(proposal.id, lot.id)}}</div>
                    </div>
                  </td>
                </tr>
                <!--end:lotHeader-->
                <!--begin:lotContent-->
                <template v-if="this.lotToggled(lot.id)">
                  <tr v-for="chiffrage in this.proposals[0]?.chiffrages.filter(c => c.lot.id === lot.id).sort(function(a, b) {
                      if (a.order < b.order) { return -1; }
                      if (a.order > b.order) { return 1; }
                      return 0;
                    })" :key="chiffrage.ouvrageId" class="h-100 lot-content">
                    <td class="py-0">
                      <p>{{ chiffrage.title }}<span @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                                                    @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                                                    class="ms-2 bi bi-info-circle text-muted">
                        <span v-if="chiffrage.ouvrageId === this.tooltip" class="ms-2">
                          <span v-if="chiffrage.description">Description : {{chiffrage.description}} — </span>
                          <span v-if="chiffrage.qt">Quantité : {{chiffrage.qt}} — </span>
                          <span v-if="this.project.featureMultiLocation && chiffrage.locations.map(l => l.name)">Emplacement : {{ chiffrage.locations.map(l => l.name).join(", ") || "Sans emplacement"}}</span>
                          <span v-else-if="chiffrage.location?.name">Emplacement : {{chiffrage.location?.name}}</span>
                        </span>
                      </span></p>
                    </td>
                    <td class="py-0"></td>
                    <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell px-2 py-0 h-100">
                      <div class="h-100 w-100 border-start border-end border-1 border-primary text-center" style="height: 100%;"><span>{{ this.getProposalChiffrageForOuvrage(proposal, lot.id, chiffrage.ouvrageId) }}</span></div>
                    </td>
                  </tr>
                </template>
                <!--end:lotContent-->
              </template>
              <!--end::tableContent-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="2">Total HT</td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-middle px-2 py-0 h-100">
                  <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                    <div class="align-self-center">
                      {{ this.proposalTotal(proposal.id)}} €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="2">TVA</td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-text-bottom px-2 py-0 h-100">
                  <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="cell h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                    <div class="align-self-center">
                      {{ this.proposalVatTotal(proposal.id) }} €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:total-->
              <tr class="h-100 lot-header">
                <td class="text-end" colspan="2">Total TTC</td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="d-table-cell align-middle px-2 py-0 h-100">
                  <div style="border-right: 1px solid #195D5C !important; border-left: 1px solid #195D5C !important;" class="h-100 w-100 d-flex justify-content-center border-start border-end border-top border-top-dotted border-1 border-primary text-center text-primary fw-bold">
                    <div class="align-self-center">
                      {{ (parseFloat(this.proposalTotal(proposal.id))+parseFloat(this.proposalVatTotal(proposal.id))).toFixed(2)}} €
                    </div>
                  </div>
                </td>
              </tr>
              <!--end:total-->

              <!--begin:roundedBottom-->
              <tr>
                <td></td>
                <td></td>
                <td v-for="proposal in this.proposals" :key="proposal.id" class="px-2 py-0 h-100">
                  <div @click="modalEditProposalShow(proposal.id)"
                      class="edit-proposal-button rounded-bottom h-100 w-100 border border-primary border-top-0 text-center text-white bg-primary p-2">
                    <i class="bi print-hidden bi-pencil-fill text-white"></i>
                  </div>
                </td>
              </tr>
              <!--end:roundedBottom-->
            </tbody>
            <!--end::body-->
          </table>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Content-->

  <!--begin::Modal Edit Tender-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalEditProposalClose()"
       :style="this.modals.editProposal.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-700px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditProposalClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-700px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Modifier le chiffrage</h3>
                        <p class="">Proposé par {{ this.getProposalById(this.modals.editProposal.id)?.contact?.company || this.getProposalById(this.modals.editProposal.id)?.contact?.name }}</p>
                      </div>
                      <div class="">
                        <div class="" v-for="lot in this.getLotsForProposalById(this.modals.editProposal.id)" :key="lot.id">
                          <h4 class="mt-3 mb-0">{{ lot.fullName }}</h4>
                          <p v-if="this.getProposalById(this.modals.editProposal.id).chiffrages.filter(c => c?.lot?.id === lot?.id).length === 0">Aucun ouvrage pour ce lot, ajoutez un ouvrage dans le descriptif puis cliquez sur "Mettre à jour les ouvrages de la consultation" dans Consultation > Suivi.</p>
                          <div class="row"
                               v-for="chiffrage in this.getProposalById(this.modals.editProposal.id)?.chiffrages?.filter(l => l.lot.id === lot.id).sort(function(a, b) {
                                  if (a.order < b.order) { return -1; }
                                  if (a.order > b.order) { return 1; }
                                  return 0;
                                })"
                               :key="chiffrage.id">
                            <div class="col-6 col-form-label text-gray-700 fw-light">{{ chiffrage.title }}
                              <span @mouseenter="showOuvrageInfoTooltip(chiffrage.ouvrageId, true)"
                                    @mouseleave="showOuvrageInfoTooltip(chiffrage.ouvrageId, false)"
                                    class="ms-2 bi bi-info-circle text-muted">
                                <span v-if="chiffrage.ouvrageId === this.tooltip" class="ms-2">
                                  <span>{{chiffrage.description}}</span>
                                  <span v-if="chiffrage.qt"> — Qt: {{chiffrage.qt}}</span>
                                  <span v-if="chiffrage.location?.name"> — {{chiffrage.location?.name}}</span>
                                </span>
                              </span>
                            </div>
                            <div class="col-6 py-1 d-flex align-middle my-auto">
                              <div class="input-group align-middle">
                                <input class="form-control" type="number" @mousewheel="$event.preventDefault()" v-model="this.modals.editProposal.chiffrages[chiffrage.id].value">
                                <span class="input-group-text">€ HT</span>
                              </div>
                              <div class="ms-2 input-group align-middle">
                                <input class="form-control" type="number" @mousewheel="$event.preventDefault()" :placeholder="this.modals.editProposal.vatDefaultValue" v-model="this.modals.editProposal.chiffrages[chiffrage.id].vatRate">
                                <span class="input-group-text">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalEditProposalClose()" class="me-2 btn btn-lg btn-light-primary btn-active-accent">
                        Annuler
                      </button>
                      <button type="button" @click="modalEditProposalValidate()" class="btn btn-lg btn-primary btn-active-light-primary">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Modal Edit Tender-->
</template>

<script>
import ProgressCard from "@/views/_core/components/ProgressCard";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import kalmStore from "@/store";
import PrintHeader from "@/views/project/tender/PrintHeader";
import {ALL_CONTACTS_QUERY, CURRENT_USER_QUERY, GET_PROPOSALS_FOR_PROJECT} from "@/graphql/graphql";

export default {
  name: "TenderPage",
  components: { ProgressCard, ProjectBreadcrumbComponent, PrintHeader},
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    }
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled || this.modals.editProposal.show;
    },
    lots(){
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).lots
    },
    locations(){
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).locations
    },
    proposals() {
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).sort()
    },
    storeIsReady() {
      return kalmStore.getters.currentProjectFocus
          && kalmStore.getters.currentProjectFocus !== "0"
          && kalmStore.getters.currentProjectFocus !== 0
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus;
    },
    project() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus);
    },
    card_count_proposals_created(){
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).length
    },
    card_count_proposals_sent(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
  },
  methods: {
    getLotsForProposalById(proposalId){
      const proposal = this.getProposalById(proposalId);
      let lots = []
      if(proposal?.lots) {
        lots = JSON.parse(JSON.stringify(proposal.lots)).sort(this.sortLots)
      }
      return lots
    },
    sortLots(a,b) {
      const regex = /\d+/g;
      let aArray = a.number.match(regex) || [];
      let bArray = b.number.match(regex) || [];

      return this.sortLotNumber(aArray, bArray)
    },
    sortLotNumber(aArray, bArray) {
      if(aArray.length === 0 && bArray.length === 0) {
        return 0
      }
      else if(aArray.length === 0 && bArray.length !== 0) {
        return -1
      }
      else if(aArray.length !== 0 && bArray.length === 0) {
        return 1
      }
      else {
        const a = aArray[0];
        const aRest = aArray.length > 1 ? aArray.slice(1) : []
        const b = bArray[0];
        const bRest = bArray.length > 1 ? bArray.slice(1) : []

        if(parseInt(a) < parseInt(b)) {
          return -1
        }
        else if(parseInt(a) > parseInt(b)) {
          return 1
        }
        else {
          return this.sortLotNumber(aRest, bRest);
        }
      }
    },
    printDocument() {
      window.print();
    },
    modalEditProposalShow(id){
      this.modals.editProposal.id = id;

      let copyChiffrages = JSON.parse(JSON.stringify(this.getProposalById(id).chiffrages))

      copyChiffrages.forEach(c => {
        this.modals.editProposal.chiffrages[c.id] = c;
      });

      this.modals.editProposal.vatDefaultValue = this.getProposalById(id).vatDefaultValue;
      this.modals.editProposal.show = true;
    },
    modalEditProposalClose(){
      this.modals.editProposal.show = false;
      this.modals.editProposal.vatDefaultValue = '';
      this.modals.editProposal.id = '';
    },
    modalEditProposalValidate(){
      const proposalId = this.modals.editProposal.id;
      const newChiffrage = JSON.stringify({ chiffrages: Object.values(this.modals.editProposal.chiffrages) });
      const apolloClient = this.$apollo;
      kalmStore.commit("updateChiffrage", {proposalId, newChiffrage, apolloClient})
      this.modalEditProposalClose();
      this.$forceUpdate();
    },
    getProposalById(proposalId){
      return kalmStore.getters.getProposalById(proposalId);
    },
    proposalSubtotal(proposalId, lotId) {
      const proposal = kalmStore.getters.getProposalById(proposalId);

      if(proposal.lots.map(l => l.id).includes(lotId)){
        return proposal.chiffrages.filter(c => c.lot.id === lotId)
            .map(c => {
              const ret = parseFloat(c?.value)
              return isNaN(ret) ? 0 : parseFloat(c?.value)
            })
            .reduce((a,b) => a + b, 0)
            .toFixed(2) + " € HT";
      }
      else {
        return "Non consulté"
      }

    },
    proposalTotal(proposalId) {
      return kalmStore.getters.getProposalById(proposalId).chiffrages
          .map(c => {
            const ret = parseFloat(c?.value)
            return isNaN(ret) ? 0 : parseFloat(c?.value)
          })
          .reduce((a,b) => a + b, 0)
          .toFixed(2);
    },
    proposalVatSubtotal(proposalId, lotId) {
      const chiffrages = kalmStore.getters?.getProposalById(proposalId)?.chiffrages;

      if(chiffrages) {
        return kalmStore.getters.getProposalById(proposalId)
            .chiffrages.filter(c => c.lot.id === lotId)
            .map(c => parseFloat(c?.vatValue))
            .reduce((a,b) => a + b, 0);
      } else {
        return 0
      }
    },
    proposalVatTotal(proposalId) {
      return kalmStore.getters.getProposalById(proposalId)
          .chiffrages.map(c => parseFloat(c?.vatValue))
          .reduce((a,b) => a + b, 0)
          .toFixed(2);
    },
    toggleLot(lotId) {
      if(this.toggledLots.includes(lotId)) {
        this.toggledLots = [...this.toggledLots.filter(l => l !== lotId)]
      }
      else {
        this.toggledLots.push(lotId)
      }
    },
    lotToggled(lotId) {
      return this.toggledLots.includes(lotId)
    },
    showOuvrageInfoTooltip(ouvrageId, status){
      this.tooltip = status ? ouvrageId : '';
    },
    getProposalChiffrageForOuvrage(proposal, lot, ouvrageId) {
      const chiffrageValue = proposal.chiffrages.find(c => c.ouvrageId === ouvrageId)?.value
      if(proposal.lots.map(l => l.id).includes(lot)) {
        return parseFloat(chiffrageValue || "0") + " € HT"
      }
      else {
        return "-"
      }
    }
  },
  data() {
    return {
      lines: [],
      contacts: [],
      modals: {
        editProposal: {
          show: false,
          vatDefaultValue: '',
          id: '',
          chiffrages: {}
        }
      },
      tooltip: '',
      toggledLots: []
    }
  },
  async mounted() {

    if((kalmStore.getters.currentProjectFocus
        && kalmStore.getters.currentProjectFocus !== "0"
        && kalmStore.getters.currentProjectFocus !== 0)) {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }

  },
  watch: {
    'currentProjectFocus': async function() {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  }
}
</script>

<style scoped>
  .blur-content{
    filter: blur(5px);
  }

  .edit-proposal-button {
    cursor: pointer;
  }
  .edit-proposal-button:hover, .edit-proposal-button:hover i {
    cursor: pointer;
    background-color: white !important;
    color: gray !important;
  }

  /* Removes the arrows from number inputs*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  @media print {
    .print-hidden {
      display: none;
    }

    .print-noindent {
      margin-left: 0 !important;
    }

    tr, th {
      break-inside: avoid;
    }
  }
</style>