<template>
  <div :class="modalActive ? 'blur-content' : ''">
    <ProjectBreadcrumbComponent
        title="Suivi de Chantier"
        current_route="project.monitoring"
        :project_id=$route.params.id
        :options="[{name: 'Cockpit de chantier', id: 'project.monitoring'}, {name: 'App Chantier', type:'link', url: currentUser.appsheetUrl, disabled: !this.currentUser.appsheetUrl},]"
    ></ProjectBreadcrumbComponent>
    <!--begin::Row-->
    <!--  <div class="row">
        <div class="col-12">
          <ProgressCard
              card_type="kalm"
              card_title="Suivi de chantier"
              card_subtitle="Dernière réunion il y a 2 jours"
              card_text_line_1="7 ouvrages réalisés"
              card_text_line_2="90 restants"
              :card_progress_percent="8"></ProgressCard>
        </div>
      </div>-->
    <!--end::Row-->
    <div class="row g-5">

    </div>
    <h2 class="text-dark fw-bolder mt-10 mb-5">Comptes-rendus</h2>
    <div class="row">
      <div v-if="!this.monitoringReports || this.monitoringReports.length === 0">
        Les comptes-rendus générés avec l'application de suivi de chantier apparaîtront ici.
      </div>
      <div v-for="report in this.monitoringReports" :key="report.id" class="col-12 col-sm-6 col-md-4">
        <a class="card card-stretch my-2 h-100 " :href="report.url" target="_blank">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Section-->
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-50px me-5">
                <span class="symbol-label bg-light-primary">
                  <!--begin::Svg Icon | path: icons/duotone/Home/Library.svg-->
                  <i class="bi bi-file-text"></i>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div>
                <a v-show="report.reportName !== null" class="fs-4 text-gray-800 fw-bolder">Compte-rendu de chantier n°{{ report.reportNumber }}</a>
                <div v-show="report.reportDate !== null" class="fs-7 text-muted fw-bold mt-1">{{ report.reportDate }}</div>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Section-->
          </div>
          <!--end::Body-->
        </a>
      </div>
    </div>
    <h2 class="text-dark fw-bolder mt-10 mb-5">Tableau de bord</h2>
    <div class="row g-5">
      <div class="col-12">
        <div class="card p-2">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-calendar4-event"></i>Planning de chantier</span>
            </h3>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-borderless mb-0 align-middle">
                <thead>
                <tr>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-50px"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="lot in currentProject.lots" :key="lot.id">
                  <tr>
                    <td class="px-0" :class="lot.number.includes('.') ? 'ps-5' : ''">
                      <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                      <span class="text-muted fw-bold d-block mt-1">{{ lot.assignedCompany?.id ? lot.assignedCompany.company || lot.assignedCompany.name : "Non attribué"}}</span>
                      <span v-if="lot.startDate || lot.endDate" class="text-muted fw-light d-block mt-1"><i class="bi bi-calendar4-event me-2"></i>{{ lot.startDate || "À déterminer"}} > {{ lot.endDate || "À déterminer"}}</span>
                    </td>
                    <td class="text-end w-100px pe-0">
                      <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                              v-tooltip:bottom="'Modifier'"
                              @click="modalEditLotPlanningShow(lot.id)">
                        <span class="bi bi-pencil-fill"></span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0 row" :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'">
                      <div class="col-1" style="width: 20px;">
                        <i class="bi bi-chat-text align-baseline me-2"></i>
                      </div>
                      <div class="col-11">
                        <p v-if="lot.comment" class="text-muted fw-light fs-6" style="white-space: pre;">{{ lot.comment }}</p>
                        <p v-else class="text-muted fw-light fs-6">Aucun commentaire</p>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear"></i>Informations importantes générales</span>
            </h3>
          </div>
          <div class="card-body w-100 row pt-2">
            <div class="col-12">
              <div class="form-group w-100 h-100 pb-9 mb-3">
                <label class="text-muted" >Compte-rendus — Informations importantes par défaut <i class="bi bi-info-circle" v-tooltip:bottom="`Code d\'immeuble, précaution importante... ce paragraphe sera la valeur par défaut de la rubrique &quot;Informations Importantes&quot; des comptes-rendus de chantier de ce projet. Vous pourrez la personnaliser sur chaque compte-rendu dans l\'application Kalm.`"></i><span></span></label>
                <textarea style="height:100%;" v-model="this.projectProperties.importantInfo" class="form-control form-control-lg form-control-solid w-100" type="text"></textarea>
              </div>
            </div>
            <div class="flex-column text-end">
              <button :disabled="this.isArchived" class="btn btn-primary btn-active-accent"
                      @click="this.updateProjectProperties()">
                Enregistrer
                <span v-if="this.buttonProjectPropertiesLoading" class="ms-1 spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!--begin::Card-->
        <div class="card card-stretch h-100 mh-100 p-2 flex-grow-1">
          <!--begin::Body-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear-wide-connected"></i>Synchronisation descriptif &lt;&gt; chantier</span>
            </h3>
          </div>
          <div class="card-body pt-3 mt-n3 align-content-center d-flex">
            <div class="align-self-center my-auto">
              <label class="me-2">Importer mon descriptif dans l'app de suivi de chantier :</label>
              <button class="btn btn-light btn-active-accent fw-bolder" @click="this.modalUploadTaskShow()">Lancer l'import</button>
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Card-->
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM UPLOAD TASKS-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalUploadTaskClose()"
       :style="this.modalUploadTask.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUploadTaskClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer l'import descriptif > chantier</h3>
                      </div>
                      <p>Tous les nouveaux ouvrages de votre descriptif vont être importés comme des tâches dans l'application de suivi de chantier.</p>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalUploadTaskClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUploadTaskValidate()" class="btn btn-lg btn-primary ms-2">
                        Importer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT LOT -->
  <div class="modal"
       tabindex="-1"
       :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-1">
                        <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début (AAAA-MM-JJ)</label>
                        <label class="ms-1 fs-8 text-muted form-label">(AAAA-MM-JJ)</label>
                        <input type="text" v-model="modals.editLotPlanning.lot.startDate" class="form-control form-control-lg form-control-solid" placeholder="2023-07-01">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                        <label class="ms-1 fs-8 text-muted form-label">(AAAA-MM-JJ)</label>
                        <input type="text" v-model="modals.editLotPlanning.lot.endDate" class="form-control form-control-lg form-control-solid" placeholder="2023-08-01">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="mt-5 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                        <textarea v-model="modals.editLotPlanning.lot.comment" class="form-control form-control-lg form-control-solid" placeholder="Pose du parquet dans les chambres lundi et mardi"></textarea>
                      </div>
                      <!--end::Form Group-->

                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalEditLotPlanningCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalEditLotPlanningValidate" class="btn btn-lg btn-primary ms-2">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ProgressCard from "@/views/_core/components/ProgressCard";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import {CURRENT_USER_QUERY, GET_CRS_FOR_PROJECT, MUTATION_PROJECT_UPLOAD_TASKS} from "@/graphql/graphql";
import kalmStore from "@/store";

export default {
  name: "MonitoringPage",
  components: {/*ProgressCard, */ProjectBreadcrumbComponent},
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY
    },
    monitoringReports: {
      query: GET_CRS_FOR_PROJECT,
      variables () {
        return {
          projectId: kalmStore.getters.currentProjectFocus,
        }
      },
      skip() {
        return !(kalmStore.getters.currentProjectFocus
            && kalmStore.getters.currentProjectFocus !== "0"
            && kalmStore.getters.currentProjectFocus !== 0)
      },
    }
  },
  data() {
    return {
      currentUser: {},
      monitoringReports: [],
      modalUploadTask: {
        show: false,
      },
      modals: {
        editLotPlanning: {
          show: false,
          lotId: "",
          lot: {
            comment: "",
            startDate: "",
            endDate: "",
          }
        }
      },
      buttonProjectPropertiesLoading: false,
      editProject: {
        importantInfo: "",
      },
    }
  },
  methods: {
    modalEditLotPlanningShow(lotId) {
      this.modals.editLotPlanning.lotId = lotId;

      const lot = this.currentProject.lots.find(l => l.id === lotId);

      console.log(lot)

      this.modals.editLotPlanning.lot.comment = lot.comment;
      this.modals.editLotPlanning.lot.startDate = lot.startDate;
      this.modals.editLotPlanning.lot.endDate = lot.endDate;

      this.modals.editLotPlanning.show = true;
    },
    modalEditLotPlanningCancel() {
      this.modals.editLotPlanning.show = false;

      this.modals.editLotPlanning.lotId = "";
      this.modals.editLotPlanning.lot.comment = "";
      this.modals.editLotPlanning.lot.startDate = "";
      this.modals.editLotPlanning.lot.endDate = "";
    },
    modalEditLotPlanningValidate() {

      kalmStore.dispatch("editLotPlanning", {
        lotId: this.modals.editLotPlanning.lotId,
        apolloClient: this.$apollo,
        startDate: this.modals.editLotPlanning.lot.startDate,
        endDate: this.modals.editLotPlanning.lot.endDate,
        comment: this.modals.editLotPlanning.lot.comment,
      })

      this.modalEditLotPlanningCancel();
    },
    modalUploadTaskShow() {
      this.modalUploadTask.show = true;
    },
    modalUploadTaskValidate() {
      this.modalUploadTaskClose();
      this.uploadTasks();
    },
    modalUploadTaskClose() {
      this.modalUploadTask.show = false;
    },
    uploadTasks() {
      this.$apollo.mutate(
          {
            mutation: MUTATION_PROJECT_UPLOAD_TASKS,
            variables: {
              projectId: kalmStore.getters.currentProjectFocus,
            },
            skip() {
              return !(kalmStore.getters.currentProjectFocus
                  && kalmStore.getters.currentProjectFocus !== "0"
                  && kalmStore.getters.currentProjectFocus !== 0)
            },
          }
      )
    },
    storeIsReady() {
      return kalmStore.getters.currentProjectFocus
          && kalmStore.getters.currentProjectFocus !== "0"
          && kalmStore.getters.currentProjectFocus !== 0
    },
    updateProjectProperties(){
      this.buttonProjectPropertiesLoading = true;

      setTimeout(() => {
        this.buttonProjectPropertiesLoading = false;
      }, 350);

      const apolloClient = this.$apollo;
      const newProperties = {
        importantInfo: this.projectProperties.importantInfo,
      }

      kalmStore.dispatch("updateProjectProperties", {apolloClient, newProperties})
    },
  },
  computed: {
    modalActive() {
      return kalmStore.state.display.profileToggled || this.modalUploadTask.show || this.modals.editLotPlanning.show
    },
    currentProject() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus)
    },
    isArchived() {
      return this.currentProject.archived
    },
    projectProperties: {
      get() {
        return {
          importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
        }
      },
      set(newValue) {
        this.editProject = newValue;
      }
    },
  }
}
</script>

<style scoped>

</style>