<template>
  <!--begin::Content-->
  <div :class="{'blur-content': this.isModalActive}">
    <ProjectBreadcrumbComponent
        title="Consultation"
        current_route="project.tender.manage"
        :project_id=$route.params.id
        :options="[{name: 'Suivi', id: 'project.tender.manage'},{name: 'Comparatif', id: 'project.tender.table'},{name: 'Scénarios', id: 'project.tender.scenarios', disabled: !this.currentUser?.groups?.includes('plan_premium')},]"
    ></ProjectBreadcrumbComponent>
    <!--begin::Row-->
    <div class="row print-hidden">
      <div class="col-12">
        <ProgressCard
            card_type="kalm"
            card_title="DCE • Consultation"
            :card_text_line_1="'' + this.card_count_proposals_sent + ((this.card_count_proposals_sent !== 1) ? ' descriptifs envoyés' : ' descriptif envoyé')"
            :card_text_line_2="'' + this.card_count_proposals_received + ((this.card_count_proposals_received !== 1) ? ' chiffrages reçus' : ' chiffrage reçu')"
            :card_progress_percent=(card_count_proposals_sent+card_count_proposals_received)/this.card_count_proposals_created*50>
        </ProgressCard>
      </div>
    </div>
    <!--end::Row-->
<!--    <h2 class="text-dark fw-bold mt-8 fs-1 mb-6">Gestion de la consultation</h2>
    <div class="card">
      <div class="card-body">
        <label>Importer le descriptif dans le module de suivi de chantier :</label>
        <button class="btn btn-light btn-active-accent ms-2">Importer</button>
      </div>
    </div>-->

    <h2 class="text-dark fw-bold mt-8 fs-1 mb-2">Suivi de la consultation</h2>
    <!--begin::Row-->
    <div class="row col-12 h-100">
      <!--begin::Card-->
      <div v-for="proposal in this.proposals" :key="proposal.id"
            class="col-12 col-sm-6 col-xl-4 col-xxl-3 flex-grow-1 container px-2 g-5 h-auto">
        <div class="card h-100">
          <div class="card-body">
            <!-- TITLE & LOTS -->
            <h3 class="mb-1">{{ getContactNameById(proposal.contact.id) }}</h3>
            <div v-if="proposal.modeLots === 'ALL'" class="text-muted">Consulté sur tous les lots</div>
            <div v-else-if="proposal.lots.length === 1" class="text-muted">Consulté sur le lot {{ proposal.lots[0].number }}</div>
            <div v-else-if="proposal.lots.length === 0" class="text-muted">Consulté sur aucun lot</div>
            <div v-else-if="proposal.lots.length >= 1" class="text-muted">Consulté sur les lot {{ proposal.lots.map(l => l.number).join(", ") }}</div>

            <div v-if="proposal.steps && proposal.steps.length">
              <div class="mt-3">
                <div v-for="step in JSON.parse(proposal.steps)" :key="step.id" class="text-gray-800">
                  <span class="me-2 bi" :class="step.status ? 'bi-check-circle-fill' : 'bi-circle'"></span>{{ step.name }}
                </div>
              </div>
            </div>

            <!-- NOTES -->
            <div v-if="proposal.notes" class="mt-8">
              <label class="text-muted">Notes :</label>
              <div class="w-100 border-0 text-dark"
                        style="min-height: 66px;"
                        >{{ proposal.notes }}</div>
            </div>

          </div>
          <div class="card-footer d-flex p-0 justify-content-center">
            <button type="button"
                    @click="modalProposalEditShow(proposal.id)"
                    class="btn flex-grow-1 rounded-0 btn-active-light-primary"
                    style="border-bottom-left-radius: 15px;"><i class="bi bi-pencil"></i></button>
            <button type="button"
                    @click="modalProposalDeleteShow(proposal.id)"
                    class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-trash"></i></button>
            <button type="button"
                    @click="modalDownloadShow(proposal.id)"
                    class="btn flex-grow-1 rounded-0 btn-active-light-primary" style="border-bottom-right-radius: 15px;"><i class="bi bi-download"></i></button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4 col-xxl-3 container px-2 g-5">
        <div class="h-100 p-auto">
          <div @click="modalProposalCreateShow()"
               class="d-flex btn justify-content-center align-content-center h-100 border border-1 border-dashed border-hover-secondary rounded border-active active border-primary py-10">
            <i class="bi bi-plus-square-dotted text-primary align-self-center p-0"></i>
          </div>
        </div>
      </div>

      <!--end::Card-->
    </div>
    <!--end::Row-->

    <h2 class="text-dark fw-bold mt-8 fs-1 mb-2">Options</h2>
    <div class="row col-12">
      <div class="card">
        <div class="card-body">
          <button @click="this.modalUpdateOuvragesShow()"
              class="btn btn-light btn-active-accent">Mettre à jour les ouvrages de la consultation
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--end::Content-->

  <!-- MODAL - CREATE -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalCreateClose()"
       :style="this.modals.proposalCreate.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalCreateClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div :class="this.modals.proposalCreate.page === 0 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <label class="fs-6 me-2 fw-bolder text-dark form-label">Entreprise</label>
                        <router-link :to="{name: 'contacts'}" class="fs-8 align-self-end form-label">+ Importer une nouvelle entreprise</router-link>
                        <select v-model="this.modals.proposalCreate.contact" class="form-select form-select-solid">
                          <option v-for="contact in [{id: '', name: 'Sélectionner un contact...', disabled: true}, ...this.contacts.filter(c => c.type === 'COMPANY')]" :key="contact.id" :value="contact.id" :disabled="contact?.disabled">{{ contact.company || contact.name }}</option>
                        </select>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="this.modals.proposalCreate.page++"
                              :disabled="!this.modals.proposalCreate.contact"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->

              <!--begin::Content1-->
              <div :class="this.modals.proposalCreate.page === 1 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="">Pour {{ getContactNameById(this.modals.proposalCreate.contact) }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="mb-10 w-100">
                        <div>
                          <label class="fs-6 mb-2 fw-bolder form-label">Consultation sur</label>
                          <select class="form-select form-select-solid" v-model="this.modals.proposalCreate.modeLots" aria-label="Select project">
                            <option v-for="mode in [{id: 'ALL', name: 'Tous les lots'}, {id: 'PARTIAL', name: 'Une partie des lots'}]" :value="mode.id" :name="mode.name" :key="mode.id">{{ mode.name }}</option>
                          </select>
                        </div>
                        <div class="mt-3" v-if="this.modals.proposalCreate.modeLots === 'PARTIAL'">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lots concernés</label>
                            <p class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"></p>
                          </div>
                          <!--begin::Form Group-->
                          <div v-for="lot in lots" :key="lot.id">
                            <!--begin:Option-->
                            <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.proposalCreate.lots">
                              </span>
                            </label>
                            <!--end::Option-->
                          </div>
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalCreateValidate()"
                              :disabled="this.modals.proposalCreate.modeLots === 'PARTIAL' && !this.modals.proposalCreate.lots.length"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Suivant >
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content1-->

              <!--begin::Content2-->
              <div :class="this.modals.proposalCreate.page >= 2 ? 'd-flex' : 'd-none'" class="flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="">Pour {{ getContactNameById(this.modals.proposalCreate.contact) }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Demande de chiffrage créée</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span>Vous pouvez désormais mettre à jour son statut d'avancement et télécharger le DCE en format Excel dans l'onglet suivi. Dès que vous recevrez le chiffrage, vous pourrez le saisir dans l'onglet Comparatif afin de compléter le tableau de synthèse.</span>
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalCreateClose()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM DELETE CONTACT-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalDeleteClose()"
       :style="this.modals.proposalDelete.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalDeleteClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Confirmer la suppression</h3>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalProposalDeleteClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalProposalDeleteValidate()" class="btn btn-lg btn-primary ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - DOWNLOAD -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="modalDownloadClose()"
       :style="this.modals.download.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalDownloadClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content2-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Demande de chiffrage</h3>
                        <p class="">Pour {{ getContactNameById(this.modals.download.contactId) }}</p>
                      </div>
                      <!--end::Heading-->

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->
                      <!--begin::Form Group-->
                      <div class="mb-5 w-100">
                        <div class="d-flex justify-content-between">
                          <label class="fs-6 mb-2 fw-bolder text-dark form-label">Fichiers à télécharger et partager :</label>
                        </div>
                        <div class="mt-2">
                          <input type="checkbox" class="form-check-input" id="includeChiffrages" v-model="this.modals.download.includeChiffrages">
                          <label class="form-check-label text-gray-800 ms-2 text-muted fs-6 mb-2 text-dark form-label" for="includeChiffrages">
                            Inclure les prix dans le fichier
                          </label>
                        </div>
                        <div class="row g-1">
                          <div class="col-6">
                            <button @click="downloadAsXlsx(this.modals.download.proposalId)"
                                    class="w-100 btn btn-light btn-active-accent align-content-center"><span class="bi bi-file-earmark-excel align-self-baseline"></span> Excel</button>
                          </div>
                          <div class="col-6">
                            <button class="w-100 btn btn-light btn-active-accent" disabled><span class="bi bi-file-earmark-pdf"></span> Pdf (à venir)</button>
                          </div>
                        </div>
                      </div>
                      <!--end::Form Group-->
                    </div>
                  </div>
                  <!--end::Step-->
                  <!--begin::Alert-->
                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                      <!--begin::Title-->
                      <h5 class="mb-1">Demande de chiffrage créée</h5>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <span>Vous pouvez télécharger le DCE et l'envoyer à {{ getContactNameById(this.modals.download.contactId) }}</span>
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Alert-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalDownloadClose()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Terminer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - EDIT -->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalProposalEditClose()"
       :style="this.modals.proposalEdit.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalProposalEditClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content2-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm"
                     class="pb-5 w-100 w-sm-400px">
                  <!--begin::Step-->
                  <div class="pb-3 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="">
                        <h3 class="fw-bolder text-dark display-6">Modifier la demande de chiffrage</h3>
                        <p class="">Pour {{ getContactNameById(this.modals.proposalEdit.contactId) }}</p>
                      </div>
                      <!--end::Heading-->

                      <div class="=mt-5">
                        <label class="text-muted mt-2 mb-1">Taux de TVA par défaut pour les ouvrages :</label>
                        <div class="input-group align-middle">
                          <input class="form-control" type="text" v-model="this.modals.proposalEdit.vatDefaultValue">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>

                      <div class="mt-3">
                        <label class="text-muted mb-1">Avancement :</label>
                        <div v-for="step in this.modals.proposalEdit.formattedSteps"
                             :key="step.id"
                             class="form-check form-check-custom mb-1">
                          <input type="checkbox"
                                 class="form-check-input"
                                 :ref="step.id"
                                 v-model="this.modals.proposalEdit.modelSteps[step.id]"
                                 :id="step.id">
                          <label class="form-check-label text-gray-800 ms-2 text-muted" :for="step.id">
                            {{ step.name }}
                          </label>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label class="fs-6 mb-2 text-muted">Consultation sur :</label>
                        <select class="form-select form-select-solid" v-model="this.modals.proposalEdit.modeLots" aria-label="Select project">
                          <option v-for="mode in [{id: 'ALL', name: 'Tous les lots'}, {id: 'PARTIAL', name: 'Une partie des lots'}]" :value="mode.id" :name="mode.name" :key="mode.id">{{ mode.name }}</option>
                        </select>
                      </div>

                      <div class="mt-3" v-if="this.modals.proposalEdit.modeLots === 'PARTIAL'">
                        <div class="d-flex justify-content-between">
                          <label class="fs-6 mb-2 form-label text-muted">Lots concernés</label>
                          <p class="fs-8 mb-2 align-self-end primary text-hover-secondary fw-bolder text-primary form-label text-decoration-underline"></p>
                        </div>
                        <!--begin::Form Group-->
                        <div v-for="lot in lots" :key="lot.id">
                          <!--begin:Option-->
                          <label class="d-flex flex-stack mb-6 cursor-pointer">
                              <span class="d-flex align-items-center me-2">
                                <span class="d-flex flex-column">
                                  <span class="fs-6">{{ lot.fullName }}</span>
                                </span>
                              </span>
                            <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="lot.id" v-model="this.modals.proposalEdit.lots">
                              </span>
                          </label>
                          <!--end::Option-->
                        </div>
                      </div>

                      <div class="mt-3">
                        <label class="text-muted mt-2 mb-1">Notes :</label>
                        <div class="">
                          <textarea class="form-control" v-model="this.modals.proposalEdit.notes"></textarea>
                        </div>
                      </div>

                      <!--
                        <div class="mb-5 w-100">
                          <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                          </div>
                          <div class="input-group input-group-lg input-group-solid">
                            <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                            <span @click="copyLinkToClipboard()"
                                class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                              <i class="la la-copy fs-3 pe-4"></i>
                            </span>
                          </div>
                        </div>
                      -->


                    </div>
                  </div>

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button"
                              @click="modalProposalEditClose()"
                              class="me-2 btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button"
                              @click="modalProposalEditValidate()"
                              class="btn btn-lg btn-primary btn-active-light-primary">
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content2-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL - CONFIRM UPDATE OUVRAGES-->
  <div class="modal"
       tabindex="-1"
       @keydown.esc="this.modalUpdateOuvragesClose()"
       :style="this.modals.updateOuvrages.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalUpdateOuvragesClose()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <form ref="contactModalForm"
                      class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Mettre à jour la consultation</h3>
                        <p>Pour correspondre à la dernière version du descriptif, les nouveaux ouvrages seront ajoutés à la consultation, les ouvrages supprimés seront enlevés.</p>
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="this.modalUpdateOuvragesClose()" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="this.modalUpdateOuvragesValidate()" class="btn btn-lg btn-primary ms-2">
                        Mettre à jour
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCard from "@/views/_core/components/ProgressCard";
import ProjectBreadcrumbComponent from "@/views/project/ProjectBreadcrumbComponent";
import {ALL_CONTACTS_QUERY, CURRENT_USER_QUERY, GET_PROPOSALS_FOR_PROJECT} from "@/graphql/graphql";
import kalmStore from "@/store";
import writeXlsxFile from "write-excel-file";

export default {
  name: "TenderPage",
  components: { ProgressCard, ProjectBreadcrumbComponent },
  apollo: {
    contacts: {
      query: ALL_CONTACTS_QUERY,
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    }
  },
  data() {
    return {
      contacts: [],
      currentUser: {},
      modals: {
        proposalDelete: {
          show: false,
          id: '',
        },
        proposalCreate: {
          show: false,
          page: 0,
          contact: '',
          modeLots: "ALL",
          lots: [],
          proposalId: '',
        },
        proposalEdit: {
          show: false,
          proposalId: '',
          contactId: '',
          formattedSteps: [],
          modelSteps: {},
          modeLots: "ALL",
          lots: [],
          vatDefaultValue: 0,
          notes: '',
        },
        download: {
          show: false,
          includeChiffrages: false,
          proposalId: '',
          contactId: '',
        },
        updateOuvrages: {
          show: false,
        }
      }
    }
  },
  computed: {
    storeIsReady(){
      return kalmStore.getters.currentProjectFocus && kalmStore.getters.currentProjectFocus !== "0"
    },
    lots() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).lots
    },
    locations() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus).locations
    },
    proposals() {
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus)
    },
    isModalActive: function() {
      return kalmStore.state.display.profileToggled
          || this.modals.proposalCreate.show
          || this.modals.proposalEdit.show
          || this.modals.download.show
          || this.modals.proposalDelete.show
          || this.modals.updateOuvrages.show;
    },
    currentProjectFocus() {
      return kalmStore.getters.currentProjectFocus
    },
    card_count_proposals_created(){
      return kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).length
    },
    card_count_proposals_sent(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "1")?.status ? 1 : 0;
      })
      return ret
    },
    card_count_proposals_received(){
      let ret = 0;
      kalmStore.getters.getProposalsForProject(kalmStore.getters.currentProjectFocus).forEach(p => {
        ret += JSON.parse(p.steps).find(s => s.id === "2")?.status ? 1 : 0;
      })
      return ret
    },
    currentProject() {
      return kalmStore.getters.getProjectById(kalmStore.getters.currentProjectFocus)
    }
  },
  methods: {
    async downloadAsXlsx(proposalId) {
      const proposal = kalmStore.getters.getProposalById(proposalId);
      const project = this.currentProject

      const includeChiffrages = this.modals.download.includeChiffrages;

      const HEADER_ROW_CORE = [
        {
          value: 'Lot',
          fontWeight: 'bold'
        },
        {
          value: 'Emplacement',
          fontWeight: 'bold'
        },
        {
          value: 'Ouvrage',
          fontWeight: 'bold'
        },
        {
          value: 'Description détaillée',
          fontWeight: 'bold'
        },
        {
          value: 'Quantité',
          fontWeight: 'bold'
        },
        {
          value: 'Prix HT',
          fontWeight: 'bold'
        },
        {
          value: 'TVA',
          fontWeight: 'bold'
        },
        {
          value: 'Prix TTC',
          fontWeight: 'bold'
        }
      ];
      let chiffrages = [];

      // TODO: move to utils
      function sortLotNumber(aArray, bArray) {
        if(aArray.length === 0 && bArray.length === 0) {
          return 0
        }
        else if(aArray.length === 0 && bArray.length !== 0) {
          return -1
        }
        else if(aArray.length !== 0 && bArray.length === 0) {
          return 1
        }
        else {
          const a = aArray[0];
          const aRest = aArray.length > 1 ? aArray.slice(1) : []
          const b = bArray[0];
          const bRest = bArray.length > 1 ? bArray.slice(1) : []

          if(parseInt(a) < parseInt(b)) {
            return -1
          }
          else if(parseInt(a) > parseInt(b)) {
            return 1
          }
          else {
            return sortLotNumber(aRest, bRest);
          }
        }
      }
      function sortLots(a,b) {
        const regex = /\d+/g;
        let aArray = a.number.match(regex) || [];
        let bArray = b.number.match(regex) || [];

        return sortLotNumber(aArray, bArray)
      }
      function sortChiffrages(a, b) {
        if (a.order < b.order) { return -1; }
        if (a.order > b.order) { return 1; }
        return 0;
      }

      JSON.parse(JSON.stringify(proposal.lots)).sort(sortLots).forEach(l => {
        const sortedChiffrages = proposal.chiffrages.filter(c => c.lot.id === l.id).sort(sortChiffrages);

        sortedChiffrages.forEach(c => {
          if(project.featureMultiLocation){
            chiffrages.push([
              {
                type: String,
                value: c.lot.fullName ? String(c.lot.fullName) : "-"
              },
              {
                type: String,
                value: c.locations.map(l => l.name) ? c.locations.map(l => l.name).join(", ") : "-"
              },
              {
                type: String,
                value: c.title ? String(c.title) : "-"
              },
              {
                type: String,
                value: c.description ? String(c.description) : "-"
              },
              {
                type: String,
                value: c.qt ? String(c.qt) : "-"
              },
              {
                type: String,
                value: c.value && includeChiffrages ? String(c.value) : "-"
              },
              {
                type: String,
                value: ""
              },
              {
                type: String,
                value: ""
              },
            ])
          }
          else {
            chiffrages.push([
              {
                type: String,
                value: c.lot.fullName ? String(c.lot.fullName) : "-"
              },
              {
                type: String,
                value: c.location?.name ? String(c.location?.name) : "-"
              },
              {
                type: String,
                value: c.title ? String(c.title) : "-"
              },
              {
                type: String,
                value: c.description ? String(c.description) : "-"
              },
              {
                type: String,
                value: c.qt ? String(c.qt) : "-"
              },
              {
                type: String,
                value: includeChiffrages ? c.value && c.value !== 0 ? String(c.value) : "-" : "-"
              },
              {
                type: String,
                value: ""
              },
              {
                type: String,
                value: ""
              },
            ])
          }
        })
      })


      const data_core = [HEADER_ROW_CORE, ...chiffrages];
      const data_general = [[
        {
          value: 'nom',
          fontWeight: 'bold'
        },
        {
          value: 'adresse',
          fontWeight: 'bold'
        },
        {
          value: 'moe_name',
          fontWeight: 'bold'
        },
        {
          value: 'moe_number',
          fontWeight: 'bold'
        },
        {
          value: 'moe_email',
          fontWeight: 'bold'
        },
        {
          value: 'moa_name',
          fontWeight: 'bold'
        },
        {
          value: 'moa_number',
          fontWeight: 'bold'
        },
        {
          value: 'moa_email',
          fontWeight: 'bold'
        },
      ],
        [
          {
            type: String,
            value: project.name
          },
          {
            type: String,
            value: project.address
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.name || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.phone || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOE")?.contact?.email || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.name || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.phone || ""
          },
          {
            type: String,
            value: project.contacts.find(c => c.role === "MOA")?.contact?.email || ""
          },
        ]]
      const today_date = (new Date()).toISOString().slice(0, 10);

      await writeXlsxFile([data_core, data_general], {
        fileName: `kalm_chiffrage_${proposal.contact.name}_${today_date}.xlsx`,
        sheets: ['DATA', 'GENERAL']
      })
    },
    updateOuvrages() {
      const projectId = kalmStore.getters.currentProjectFocus;
      const apolloClient = this.$apollo;
      kalmStore.commit("updateOuvrages", {projectId, apolloClient})
    },
    modalProposalDeleteShow(id) {
      this.modals.proposalDelete.show = true;
      this.modals.proposalDelete.id = id;
    },
    modalProposalDeleteClose() {
      this.modals.proposalDelete.show = false;
      this.modals.proposalDelete.id = '';
    },
    modalProposalDeleteValidate() {
      const apolloClient = this.$apollo;
      const proposalId = this.modals.proposalDelete.id;
      kalmStore.commit("deleteProposal", {proposalId, apolloClient})
      this.modalProposalDeleteClose()
    },
    modalProposalCreateShow() {
      this.modals.proposalCreate.page = 0;
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.contact = '';
      this.modals.proposalCreate.lots = [];
      this.lots.forEach(l => {
        this.modals.proposalCreate.lots.push(l.id)
      })
      this.modals.proposalCreate.show = true;
    },
    modalProposalCreateClose() {
      this.modals.proposalCreate.show = false;
      this.modals.proposalCreate.contact = '';
      this.modals.proposalCreate.modeLots = "ALL";
      this.modals.proposalCreate.lots = [];
    },
    modalProposalCreateValidate() {
      this.modals.proposalCreate.page = 2;
      const projectId = kalmStore.getters.currentProjectFocus;
      const contactId = this.modals.proposalCreate.contact;
      const apolloClient = this.$apollo
      const modeLots = this.modals.proposalCreate.modeLots;
      const lots = this.modals.proposalCreate.lots;

      kalmStore.commit("createProposal", {projectId, contactId, modeLots, lots, apolloClient});
    },
    modalProposalEditShow(proposalId) {
      const proposal = this.proposals.find(p => p.id === proposalId);
      this.modals.proposalEdit.proposalId = proposalId;
      this.modals.proposalEdit.contactId = proposal.contact.id;
      this.modals.proposalEdit.modeLots = proposal.modeLots;
      this.modals.proposalEdit.lots = proposal.lots.map(l => l.id);

      this.modals.proposalEdit.formattedSteps = JSON.parse(proposal.steps);
      this.modals.proposalEdit.formattedSteps.forEach(s => {
        this.modals.proposalEdit.modelSteps[s.id] = s.status;
      });

      this.modals.proposalEdit.notes = proposal.notes;
      this.modals.proposalEdit.vatDefaultValue = proposal.vatDefaultValue;
      this.modals.proposalEdit.show = true;
    },
    modalProposalEditClose() {
      this.modals.proposalEdit.proposalId = '';
      this.modals.proposalEdit.contactId = '';
      this.modals.proposalEdit.notes = '';
      this.modals.proposalEdit.vatDefaultValue = 0;
      this.modals.proposalEdit.formattedSteps = [];
      this.modals.proposalEdit.modelSteps = {};
      this.modals.proposalEdit.show = false;
    },
    modalProposalEditValidate() {
      const proposalId = this.modals.proposalEdit.proposalId;

      this.modals.proposalEdit.formattedSteps.map(s => {
        s.status = this.modals.proposalEdit.modelSteps[s.id] || false;
        return s;
      });

      const modeLots = this.modals.proposalEdit.modeLots;
      const lots = this.modals.proposalEdit.lots;

      const newProposal = {
        steps: JSON.stringify(this.modals.proposalEdit.formattedSteps),
        notes: this.modals.proposalEdit.notes,
        vatDefaultValue: parseFloat(this.modals.proposalEdit.vatDefaultValue),
      }
      const apolloClient = this.$apollo;
      kalmStore.commit("manageProposal", {proposalId, newProposal, modeLots, lots, apolloClient})
      this.modalProposalEditClose();
    },
    modalDownloadShow(proposalId){
      this.modals.download.show = true;
      this.modals.download.proposalId = proposalId;
      this.modals.download.contactId = this.proposals.find(p => p.id === proposalId).contact.id;
    },
    modalDownloadClose(){
      this.modals.download.show = false;
    },
    modalUpdateOuvragesShow(){
      this.modals.updateOuvrages.show = true;
    },
    modalUpdateOuvragesClose(){
      this.modals.updateOuvrages.show = false;
    },
    modalUpdateOuvragesValidate(){
      this.updateOuvrages();
      this.modalUpdateOuvragesClose();
    },
    getContactNameById(contactId) {
      let ret = "";
      if(contactId) {
        const contact = this.contacts.find( c => c.id === contactId );
        ret = contact?.company || contact?.name || "";
      }
      return ret;
    },
    sendDCERequest() {
      this.tender_modal_load = true;
      return new Promise(resolve => setTimeout(resolve, 1));
    },
    async onDCERequestReceive() {
      this.modals.proposalCreate.page++;
      this.getTenderProposalBySlotID(this.tender_modal_selected_slot).active = true;
    },
    copyLinkToClipboard() {
      const element = this.$refs.dceModalLinkShareField;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
  },
  async mounted() {
    if((kalmStore.getters.currentProjectFocus
        && kalmStore.getters.currentProjectFocus !== "0"
        && kalmStore.getters.currentProjectFocus !== 0)) {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  },
  watch: {
    'currentProjectFocus': async function() {
      const query = await this.$apollo.query({
        query: GET_PROPOSALS_FOR_PROJECT,
        variables: {
          projectId: kalmStore.getters.currentProjectFocus,
        },
        skip() {
          return !(kalmStore.getters.currentProjectFocus
              && kalmStore.getters.currentProjectFocus !== "0"
              && kalmStore.getters.currentProjectFocus !== 0)
        },
      });

      query.data.proposals.forEach( (newProposal) => {
        kalmStore.commit("initializeProposal", { newProposal })
      })
    }
  }
}
</script>

<style scoped>
  .blur-content{
    filter: blur(5px);
  }
</style>