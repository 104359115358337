<template>
  <div id="kt_aside" class="aside bg-info"
       :class="drawerClass">
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid bg-white">
      <!--begin::Workspace-->
      <div class="aside-workspace my-7 ps-5 pe-4 ps-lg-10 pe-lg-6" id="kt_aside_wordspace">
        <!--begin::Logo-->
        <div class="aside-logo py-2 pb-7" id="kt_aside_logo">
          <router-link :to="{name: 'dashboard'}">
            <img alt="Logo" src="/assets/media/logos/logo-compact-positive.png" fill="#01949A" class="mh-100px" />
          </router-link>
        </div>
        <!--end::Logo-->
        <!--begin::Menu-->
        <div class="menu menu-column menu-rounded menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6" data-kt-menu="true">
          <div class="hover-scroll-y pe-4 pe-lg-5" id="kt_aside_menu_scroll" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo" data-kt-scroll-wrappers="#kt_aside_wordspace" data-kt-scroll-offset="10px">
            <div class="menu-wrapper menu-column menu-fit">
              <div class="menu-item">
                <h4 class="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">GÉNÉRAL</h4>
                <div class="menu-sub menu-fit menu-sub-accordion show pb-10">
                  <div class="menu-item" v-for="item in general_items" :key="item.destination">
                    <router-link :to="{ name: item.destination }" class="menu-link py-2 link-primary"
                                 v-bind:class="(item.force_active || isPageActive(item.destination)) ? 'active' : ''" href={{item.url}}>
                      <span class="menu-icon" v-if="item.icon !== undefined">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                      <span class="menu-title">{{item.name}}</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="menu-item">
                <div v-if="this.currentUser.isSuperuser && this.currentUser.orgs.length > 0">
                  <h4 class="menu-content text-muted mb-0 ps-0 fs-6 fw-bold text-uppercase">ORG</h4>
                  <select class="form-select form-select-solid" aria-label="Select project" @change="updateOrg">
                    <option v-for="org in this.currentUser.orgs" :value="org.id" :name="org.name" :key="org.id" :selected="org.id === this.currentUser.org.id">{{ org.name }}</option>
                  </select>
                </div>
                <h4 class="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">PROJET</h4>
                <select class="form-select form-select-solid" aria-label="Select project" @change="updateFocus" @click="updateFocus">
                  <option v-for="project in projects.filter(p => !p.archived)" :value="project.id" :name="project.name" :key="project.id" :selected="project.id === currentProjectFocus">{{ project.name }}</option>
                </select>
                <br>
                <div class="menu-sub menu-fit menu-sub-accordion show pb-10">
                  <div class="menu-item" v-for="item in project_items" :key="item.destination">
                    <router-link :to="{ name: item.destination, params: { id: currentProjectFocus }}" class="menu-link py-2 link-primary"
                                 v-bind:class="{ active: item.force_active || isPageActive(item.destination), disabled: item.disabled}">
                      <span class="menu-icon" v-if="item.icon !== undefined">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                      <span class="menu-title">{{item.name}}</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Workspace-->
    </div>
    <!--end::Secondary-->
  </div>
</template>

<script>

import kalmStore from "@/store";
import {
  ALL_PROJECTS_QUERY,
  CURRENT_USER_QUERY,
  MUTATION_SUPERUSER_CHANGE_ORG
} from "@/graphql/graphql";

export default {
  name: "MainMenu",
  apollo: {
    projects: ALL_PROJECTS_QUERY,
    currentUser: CURRENT_USER_QUERY,
  },
  data: () => ({
    currentUser: {
      orgs: [],
      org: {
        id: "",
      },
      isSuperuser: false,
    },
    general_items: [
      {
        name: "Tableau de bord",
        icon: "bi-house-door",
        force_active: false,
        id: "dashboard",
        destination: "dashboard",
      },
      {
        name: "Paramètres",
        icon: "bi-gear",
        force_active: false,
        id: "settings",
        destination: "settings.account_settings",
      },
      {
        name: "Contacts",
        icon: "bi-people",
        force_active: false,
        id: "contacts",
        destination: "contacts",
      },
      {
        name: "Projets",
        icon: "bi-folder",
        force_active: false,
        id: "projects",
        destination: "projects",
      },
    ],
    project_items: [
      {
        name: "Cockpit",
        icon: "bi-bullseye",
        force_active: false,
        id: "project_home",
        destination: "project_home",
      },
      {
        name: "Descriptif",
        icon: "bi-file-text",
        force_active: false,
        id: "description",
        destination: "project.description",
      },
      {
        name: "Consultation",
        icon: "bi-journals",
        force_active: false,
        disabled: false,
        id: "description",
        destination: "project.tender",
      },
      {
        name: "Suivi de chantier",
        icon: "bi-card-checklist",
        force_active: false,
        id: "monitoring",
        destination: "project.monitoring",
      },

    ],
    windowWidth: window.innerWidth,
    asideMode: '',
    projects: []
  }),
  methods: {
    /**
     * Compares a menu page ID to the current route
     * @param page_id
     * @returns {boolean} t rue if page_id matches current route
     */
    isPageActive(page_id) {
      return String(this.$route.name).startsWith(page_id);
    },
    toggleAside() {
      kalmStore.commit('toggleAside');
    },
    updateAsideMode() {
      this.windowWidth = window.innerWidth;
      this.asideMode = (this.windowWidth < 992) ? 'drawer' : 'permanent';
      return this.asideMode;
    },
    updateFocus(event){
      const projectId = event.target.value || 0;
      const projectName = event.target[event.target.selectedIndex].name || "0";

      kalmStore.commit("setCurrentProjectFocus", {projectId, projectName})

      const current_route = String(this.$route.name);
      if(current_route === "project_home" || current_route.startsWith("project.")){
        this.$router.push({name: current_route, params: {id: projectId}})
      }
    },
    updateOrg(event) {
      const orgId = event.target.value || 0;

      this.$apollo.mutate({
        mutation: MUTATION_SUPERUSER_CHANGE_ORG,
        variables: {
          newOrgId: orgId
        },
      })

      this.$forceUpdate();
    }
  },
  async mounted() {
    window.addEventListener("resize", this.updateAsideMode);

    const projects = await this.$apollo.query({query: ALL_PROJECTS_QUERY});

    projects.data.projects.forEach( (p) => {
      const doc = JSON.parse(p.document);

      const projectId = p.id;
      const lots = p.lots;
      const locations = p.locations;
      const contacts = p.contacts;
      const name = p.name;
      const address = p.address;
      const infoOpName = p.infoOpName;
      const importantInfo = p.importantInfo;
      const archived = p.archived;
      const featureMultiLocation = p.featureMultiLocation;

      kalmStore.commit("initializeLocalProject", {
        projectId,
        name,
        address,
        infoOpName,
        importantInfo,
        archived,
        newProject: doc,
        lots,
        locations,
        contacts,
        featureMultiLocation
      })
    })

    if(!kalmStore.getters.currentProjectFocus
        || kalmStore.getters.currentProjectFocus === 0
        || kalmStore.getters.currentProjectFocus === "0"){
      kalmStore.commit("setCurrentProjectFocus",{projectId: projects.data.projects[0].id})
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.updateAsideMode);
  },
  beforeMount() {
    this.updateAsideMode()
  },
  computed: {
    asideToggled() {
      return kalmStore.state.display.asideToggled;
    },
    drawerClass() {
      let mode = this.asideMode;
      let toggled = kalmStore.state.display.asideToggled;

      if(mode === 'permanent') {
        return "";
      }
      else if(mode === 'drawer' && !toggled) {
        return "drawer drawer-start";
      }
      else if(mode === 'drawer' && toggled) {
        return "drawer drawer-start drawer-on";
      }
      return null;
    },
    currentProjectFocus() {
      return kalmStore.state.display.currentProjectFocus || 1
    }
  },
}

</script>

<style scoped>
  a.disabled {
    pointer-events: none;
  }
  a.disabled span, a.disabled span i {
    color: lightgray !important;
  }
</style>