import {
    MUTATION_TENDER_CREATE_PROPOSAL, MUTATION_TENDER_CREATE_SCENARIO,
    MUTATION_TENDER_DELETE_PROPOSAL, MUTATION_TENDER_DELETE_SCENARIO,
    MUTATION_TENDER_MANAGE_PROPOSAL,
    MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE, MUTATION_TENDER_UPDATE_OUVRAGES, MUTATION_TENDER_UPDATE_SCENARIO,
} from "@/graphql/graphql";

function sortProposals(a,b) {
    return ('' + a?.contact?.name + a.id).localeCompare(('' + b?.contact?.name + b.id));
}

function sortScenarios(a,b) {
    return ('' + a?.name + a.id).localeCompare(('' + b?.name + b.id));
}

export const tenderModule = {
    state: () => ({
        proposals: [],
        scenarios: [],
    }),
    mutations: {
        initializeProposal(state, {newProposal}) {
            const index = state.proposals.findIndex(p => p.id === newProposal.id)
            if (index === -1) state.proposals.push(newProposal)
            else state.proposals[index] = newProposal
        },
        createProposal(state, {projectId, contactId, modeLots, lots, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_CREATE_PROPOSAL,
                variables: {
                    projectId,
                    contactId,
                    modeLots,
                    lots
                },
                update: (cache, result) => {
                    const proposal = result.data.projectTenderProposalCreate.proposal;

                    state.proposals = [...state.proposals, proposal].sort(sortProposals);
                },
            })
        },
        manageProposal(state, {proposalId, newProposal, modeLots, lots, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_MANAGE_PROPOSAL,
                variables: {
                    proposalId,
                    newProposal,
                    modeLots,
                    lots,
                },
                update: (cache, result) => {
                    const proposal = result.data.projectTenderProposalManage.proposal;
                    state.proposals = [...state.proposals.filter(p => p.id !== proposalId), proposal].sort(sortProposals);
                },
            })
        },
        updateChiffrage(state, {proposalId, newChiffrage, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_PROPOSAL_UPDATE_CHIFFRAGE,
                variables: {
                    proposalId,
                    newChiffrage,
                },
                update: (cache, result) => {
                    const proposal = result.data.projectTenderProposalChiffrageUpdate.proposal;
                    state.proposals = [...state.proposals.filter(p => p.id !== proposalId), proposal].sort(sortProposals);
                },
            })
        },
        updateOuvrages(state, {projectId, apolloClient}){
            apolloClient.mutate({
                mutation: MUTATION_TENDER_UPDATE_OUVRAGES,
                variables: {
                    projectId: projectId,
                },
                update: (cache, result) => {
                    const proposals = result.data.projectTenderUpdateOuvrages.tender.proposals;
                    proposals.forEach(pn => {
                        const index = state.proposals.findIndex(ps => ps.id === pn.id);
                        state.proposals[index] = pn;
                    })
                },
            })
        },
        deleteProposal(state, {proposalId, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_DELETE_PROPOSAL,
                variables: {
                    proposalId
                },
                update: (cache, result) => {
                    if(result.data.projectTenderProposalDelete.deleted) {
                        state.proposals = [...state.proposals.filter(p => p.id !== proposalId)].sort(sortProposals)
                    }
                },
            })
        },
        initializeScenario(state, {newScenario}) {
            const index = state.scenarios.findIndex(s => s.id === newScenario.id)
            if (index === -1) state.scenarios.push(newScenario)
            else state.scenarios[index] = newScenario
        },
        createScenario(state, {projectId, name, graph, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_CREATE_SCENARIO,
                variables: {
                    projectId,
                    name,
                    graph,
                },
                update: (cache, result) => {
                    const scenario = result.data.projectTenderScenarioCreate.scenario;

                    state.scenarios = [...state.scenarios, scenario].sort(sortScenarios);
                },
            })
        },
        updateScenario(state, {scenarioId, name, graph, apolloClient}){
            apolloClient.mutate({
                mutation: MUTATION_TENDER_UPDATE_SCENARIO,
                variables: {
                    scenarioId,
                    name,
                    graph
                },
                update: (cache, result) => {
                    const scenario = result.data.projectTenderScenarioUpdate.scenario;
                    const index = state.scenarios.findIndex(s => s.id === scenario.id)
                    if(index.id === -1) {
                        state.scenarios = [...state.scenarios, scenario]
                            .sort(sortScenarios)
                    } else {
                        state.scenarios = [...state.scenarios.filter(s => s.id !== scenario.id), scenario]
                            .sort(sortScenarios)
                    }
                },
            })
        },
        deleteScenario(state, {scenarioId, apolloClient}) {
            apolloClient.mutate({
                mutation: MUTATION_TENDER_DELETE_SCENARIO,
                variables: {
                    scenarioId
                },
                update: (cache, result) => {
                    if(result.data.projectTenderScenarioDelete.deleted) {
                        state.scenarios = [...state.scenarios.filter(s => s.id !== scenarioId)].sort(sortScenarios)
                    }
                },
            })
        },
    },
    getters: {
        getProposalById: (state) => (id) => {
            return state.proposals.find(p => p.id === id)
        },
        getProposalsForProject: (state) => (projectId) => {
            return state.proposals.filter(p => p.project.id === projectId).sort(sortProposals)
        },
        getScenarioById: (state) => (id) => {
            return state.scenarios.find(s => s.id === id)
        },
        getScenariosForProject: (state) => (projectId) => {
            return state.scenarios.filter(s => s.project.id === projectId).sort(sortScenarios)
        },
    },
}
